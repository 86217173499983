import { Button, Text, useMantineTheme } from "@mantine/core";
import { withPolymorphicClickAnalytics } from "../../../analytics";
import { IconButton } from "../../../common-ui";
import { Pause } from "../../../common-ui/assets/image/Pause";
import { type ConferenceParticipant, useAsyncLoading, type VoiceTask } from "../../../sdk/agent";
import { useStopwatch } from "../../../shared-utils";
import classes from "./Button.module.css";

function ResumeCallButton({
    onHoldSince,
    outlined = true,
    onClick,
}: {
    onHoldSince: number | null;
    outlined?: boolean;
    onClick: () => Promise<void>;
}) {
    const formattedHoldTime = useStopwatch(onHoldSince, false, "m:ss");
    const theme = useMantineTheme();

    const [loading, handleHold] = useAsyncLoading(async () => {
        await onClick();
    });

    const ResumeButton = withPolymorphicClickAnalytics(Button, "Click");

    return (
        <ResumeButton
            analytics={{
                actionId: "ResumeCallButton",
            }}
            aria-label="Resume Call"
            data-testid="call-controls-resume"
            h={35}
            color={theme.colors.gray[0]}
            loading={loading}
            onClick={handleHold}
            size="sm"
            variant={outlined ? "outline" : "filled"}
        >
            <Text size="sm" c={outlined ? theme.white : theme.black}>
                Resume
            </Text>
            <Text ff="mono" c={outlined ? theme.white : theme.black} size="xs" className={classes.monospaceTimerText}>
                ({formattedHoldTime})
            </Text>
        </ResumeButton>
    );
}

function PauseCallButton({ outlined, onClick }: { outlined?: boolean; onClick: () => Promise<void> }) {
    const [loading, handleHold] = useAsyncLoading(async () => {
        await onClick();
    });

    const buttonClass = outlined ? classes.pauseIconBorderBtn : "";

    return (
        <IconButton
            analytics={{
                actionId: "PauseCallButton",
            }}
            data-testid="call-controls-pause"
            loading={loading}
            onClick={handleHold}
            radius="xl"
            size="35"
            tooltip="Hold"
            classNames={{ root: buttonClass }}
        >
            <Pause height={16} />
        </IconButton>
    );
}

interface HoldButtonProps {
    task: VoiceTask;
    outlined?: boolean;
    isOnHold: boolean;
    onResumeCallButtonClick: () => Promise<void>;
    onPauseCallButtonClick: () => Promise<void>;
    participant?: ConferenceParticipant;
}

export function HoldButton({
    task,
    outlined = false,
    isOnHold,
    onResumeCallButtonClick,
    onPauseCallButtonClick,
    participant,
}: HoldButtonProps) {
    function getHoldTimestamp(): number | null {
        if (!participant) {
            return task.holdState.onHoldSince;
        }
        return participant.hold?.since ?? null;
    }

    const onHoldSince = getHoldTimestamp();
    if (isOnHold) return <ResumeCallButton outlined onClick={onResumeCallButtonClick} onHoldSince={onHoldSince} />;
    return <PauseCallButton outlined={outlined} onClick={onPauseCallButtonClick} />;
}
