import { useGaiaWebsocket } from "@expert/gaia";
import { useTimelineStore } from "../state";

export function useRequestAutoCompleteSuggestions() {
    const websocketObj = useGaiaWebsocket();
    const { callSid, sessionId, features } = useTimelineStore();

    if (!features.isAutoCompleteEnabled || !callSid || !sessionId) {
        return {};
    }

    const requestAutoCompleteSuggestions = (textInput: string) => {
        const fetchAutoCompleteSuggestionsPayload = {
            action: "fetch-auto-complete-suggestion",
            callSid,
            sessionId: callSid,
            sessionGroupId: sessionId,
            timestamp: Date.now(),
            textInput,
        };

        websocketObj?.sendJsonMessage(fetchAutoCompleteSuggestionsPayload);
    };

    return { requestAutoCompleteSuggestions };
}
