import { Button, Text, Tooltip } from "@mantine/core";
import { withClickAnalytics } from "@soluto-private/eventualize-react";
import dayjs from "dayjs";
import { useState } from "react";
import { useTimer } from "react-timer-hook";
import { CancelCall } from "../../../common-ui";
import { useAgentSdk, useAsyncLoading, type VoiceTask } from "../../../sdk";
import { handleEndCall } from "../../eventHandlers";
import { useControlsStore, withControlsLock } from "../controls.store";

const AnalyticButton = withClickAnalytics(Button, "Click");

/**
 * This button is used to cancel a pending call that has not yet connected
 * @param task - the active voice task associated with the call
 */
export function CancelPendingCallButton({ task }: { task: VoiceTask }): JSX.Element | null {
    const agentSdk = useAgentSdk();
    const { controlLock } = useControlsStore();

    const [allowCancel, setAllowCancel] = useState<boolean>(false);

    /** delay in seconds to wait until showing the cancel button */
    const defaultDelay = 15;

    useTimer({
        expiryTimestamp: dayjs().add(defaultDelay, "seconds").toDate(),
        autoStart: true,
        onExpire: () => setAllowCancel(true),
    });

    const [isLoading, endCall] = useAsyncLoading(withControlsLock(async () => await handleEndCall(agentSdk, task)));

    return allowCancel ? (
        <Tooltip label="Cancel pending call" position="bottom" zIndex={999}>
            <AnalyticButton
                analytics={{
                    actionId: "CancelCallButton",
                }}
                aria-label="Cancel Call"
                data-testid="cancel-call-controls-leave"
                disabled={isLoading || task.status !== "assigned" || controlLock}
                loading={isLoading || controlLock}
                onClick={endCall}
                radius="xl"
                leftSection={<CancelCall height={18} width={18} stroke="white" />}
                size="sm"
                color="red.9"
            >
                <Text size="sm">Cancel</Text>
            </AnalyticButton>
        </Tooltip>
    ) : null;
}
