import { useCallback, useEffect, useState } from "react";
import { useDebouncedValue } from "@mantine/hooks";
import { useReactAnalytics } from "@soluto-private/eventualize-react";
import type { Logger } from "@expert/logging";
import { useTimelineStore } from "../state";
import { useRequestAutoCompleteSuggestions } from "./useRequestAutoCompleteSuggestions";

interface HandleAutoCompleteProps {
    input: string;
    logger: Logger;
    toolboxInput?: string;
    isSendDisabled: boolean;
    setInput: (input: string) => void;
    setPreviousInput: (input: string) => void;
    setSelectedSuggestion: (suggestion: string) => void;
}

export function useHandleAutoComplete({
    input,
    logger,
    toolboxInput,
    isSendDisabled,
    setInput,
    setPreviousInput,
    setSelectedSuggestion,
}: HandleAutoCompleteProps) {
    const { dispatcher } = useReactAnalytics();
    const { features, setNewSuggestions } = useTimelineStore();
    const [didClickSuggestion, setDidClickSuggestion] = useState(false);
    const [shouldRequestSuggestion, setShouldRequestSuggestion] = useState(false);

    const [debouncedInput, cancel] = useDebouncedValue(input, features.autoCompleteDelay);
    const { requestAutoCompleteSuggestions } = useRequestAutoCompleteSuggestions();

    const handleSuggestionClick = useCallback(
        (suggestion: string) => {
            void dispatcher.dispatchAction("Click", "AutoCompleteSuggestion", { suggestion });
            setPreviousInput(input);
            setInput(suggestion);
            setSelectedSuggestion(suggestion);
            logger.child({ module: "useHandleAutoComplete" }).info({ suggestion }, "AutoComplete suggestion selected");
            setNewSuggestions([]);
            setDidClickSuggestion(true);
        },
        [dispatcher, input, setInput, setNewSuggestions, setPreviousInput, setSelectedSuggestion, logger],
    );

    /*
        This keeps track of when we need to request suggestions.
        This should only happen once after the user stops typing
        (i.e. when input has been changed).
    */
    useEffect(() => {
        if (debouncedInput && features.isAutoCompleteEnabled) {
            setShouldRequestSuggestion(true);
        }
    }, [debouncedInput, features.isAutoCompleteEnabled]);

    useEffect(() => {
        if (
            shouldRequestSuggestion &&
            requestAutoCompleteSuggestions &&
            debouncedInput &&
            !toolboxInput &&
            !isSendDisabled
        ) {
            requestAutoCompleteSuggestions(debouncedInput);
            setShouldRequestSuggestion(false);
        }
    }, [debouncedInput, requestAutoCompleteSuggestions, shouldRequestSuggestion, toolboxInput, isSendDisabled]);

    /*
        This useEffect handles canceling the debounce.
        When handleSuggestionClick is called, input state value is
        not updated immediately within the scope of the function.
        So calling cancel there would happen too quickly
    */
    useEffect(() => {
        if (didClickSuggestion) {
            cancel();
            setDidClickSuggestion(false);
        }
    }, [didClickSuggestion, cancel]);

    return { cancel, handleSuggestionClick };
}
