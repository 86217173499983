import dayjs from "dayjs";

export const getLastSaturdayDate = (): string => {
    let currentDate = dayjs();

    while (currentDate.day() !== 6) {
        currentDate = currentDate.subtract(1, "day");
    }
    return currentDate.format("MMMM D");
};
