import { Button, Flex, Text } from "@mantine/core";
import { useState } from "react";
import { useAnalytics, withPolymorphicClickAnalytics } from "../../../../analytics";
import { getSessionId, isVoiceTask, useMostRecentTask, usePartner } from "../../../../sdk";
import type { EligibleProduct } from "../../stores";
import { useCurrentStepStore, useHomeProductSessionStore } from "../../stores";
import { SaleProgress } from "../../types";
import { RightArrowIcon } from "../Icons";
import { postDeclination } from "../../api";
import { useIntegratedFlowStore } from "../../stores/useIntegratedFlowStore";
import { useFeatureFlag } from "../../../../shared-utils";
import styles from "./StepTwoFooter.module.css";

interface EnrollSectionProps {
    product: EligibleProduct;
}

export function StepTwoFooter({ product }: EnrollSectionProps): JSX.Element {
    const AnalyticsButton = withPolymorphicClickAnalytics(Button, "ButtonClick");
    const [declining, setDeclining] = useState(false);
    const [updateProduct] = useHomeProductSessionStore((state) => [state.updateProduct]);
    const [step, updateStep] = useCurrentStepStore((state) => [state.step, state.updateStep]);
    const [integratedStep, updateIntegratedStep] = useIntegratedFlowStore((state) => [
        state.integratedStep,
        state.updateIntegratedStep,
    ]);
    const { data: isIntegratedFlowEnabled = false } = useFeatureFlag("EWP-isIntegratedFlowEnabled");
    const { data: isDynamicChecklistEnabled = false } = useFeatureFlag("Sales-isDynamicChecklistEnabled");

    const { dispatcher } = useAnalytics();
    const mostRecentTask = useMostRecentTask();
    const partner = usePartner();
    let nextStep, updateNextStep;
    if (!isVoiceTask(mostRecentTask)) {
        throw new Error("Active task is not voice task");
    }

    if (isIntegratedFlowEnabled || isDynamicChecklistEnabled) {
        nextStep = integratedStep;
        updateNextStep = updateIntegratedStep;
    } else {
        nextStep = step;
        updateNextStep = updateStep;
    }

    const decline = async () => {
        try {
            const sessionId = getSessionId();
            await postDeclination({
                callId: sessionId,
                sessionId,
                partner,
                productOfferId: product.id,
            });
            updateProduct({ ...product, ...{ saleProgress: SaleProgress.Declined } });
            setDeclining(false);
        } catch (e) {
            setDeclining(false);
        }
    };

    return (
        <Flex className={styles.footerBox}>
            <AnalyticsButton
                analytics={{
                    actionId: "EnrollmentDeclineButton",
                    eventData: {
                        nextStep,
                    },
                }}
                color="white"
                data-testid="enrollment-decline-button"
                loading={declining}
                onClick={() => {
                    setDeclining(true);
                    void decline();
                }}
                size="md"
                variant="transparent"
            >
                <Text td="underline" size="sm">
                    Decline
                </Text>
            </AnalyticsButton>
            <Button
                disabled={declining}
                className={styles.actionText}
                color="white"
                variant="outline"
                data-testid="continue-to-disclosures"
                rightSection={<RightArrowIcon />}
                onClick={() => {
                    void dispatcher
                        .withExtra({ nextStep })
                        .dispatchBusinessEvent("ButtonClicked_ContinueToDisclosures");
                    updateNextStep("LegalDisclosureStep");
                }}
            >
                Continue to disclosures
            </Button>
        </Flex>
    );
}
