import { Flex } from "@mantine/core";
import React, { useEffect } from "react";
import { useAnalytics } from "../../../analytics";
import { useIntegratedFlowStore } from "../stores/useIntegratedFlowStore";
import { StepTwoContent } from "./StepSpecific/StepTwoContent";
import styles from "./GuidedFlow.module.css";
import { StepThreeContent } from "./StepSpecific/StepThreeContent";
import type { IntegratedSalesSteps } from "./types";

export function IntegratedFlow(): JSX.Element {
    const step: IntegratedSalesSteps = useIntegratedFlowStore((state) => state.integratedStep);

    const { dispatcher } = useAnalytics();

    useEffect(() => {
        void dispatcher.dispatchBusinessEvent(`${step}_IntegratedFlow`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    return (
        <Flex className={styles.stepsContainer}>
            <>
                {step === "SuggestedOfferStep" && <StepTwoContent />}
                {step === "LegalDisclosureStep" && <StepThreeContent />}
            </>
        </Flex>
    );
}
