import { type SolveGuidedFlow } from "@expert/solve-tools";
import { useFeatureFlag } from "../../shared-utils";
import { getDeviceActivationGuide } from "../DeviceActivationGuide";
import { useDeviceActivationStore } from "../store";

export const useDeviceActivationGuide = (): SolveGuidedFlow => {
    const { data: isConversationalIVRGuideEnabled } = useFeatureFlag("isConversationalIVRGuideEnabled");

    const guideInfo = useDeviceActivationStore();

    const guide = getDeviceActivationGuide({
        ...guideInfo,
        isConversationalIVRGuideEnabled,
    });

    return guide;
};
