import { type GaiaWebSocketResponse, gaiaWsEventBus } from "@expert/gaia";
import { getLogger } from "@expert/logging";
import { useEffect } from "react";
import { useTimelineStore } from "@soluto-private/expert-workspace-timeline";
import { type AutoCompleteSuggestionPayload } from "../types";

const logger = getLogger({ module: "useGaiaAutocompleteEvents", tag: "solve" });

export const useGaiaAutoCompleteEvents = () => {
    useEffect(() => {
        const gaiaEventBusFetchAutoCompleteSuccess = gaiaWsEventBus.on(
            "gaia_ws_fetch-auto-complete-suggestion-success",
            ({ body }: GaiaWebSocketResponse) => {
                const { status, suggestions, ...rest } = (body ?? {}) as AutoCompleteSuggestionPayload;
                logger.info(
                    { suggestions, sessionId: rest.sessionGroupId, callSid: rest.sessionId },
                    "GAIA websocket received autocomplete suggestion(s)",
                );

                if (status !== 200) {
                    logger.error({ body }, "GAIA | fetch autocomplete suggestions failure");
                    return;
                }
                const parsedSuggestions = suggestions.map(({ suggestion }) => suggestion);
                const dedupedSuggestions = [...new Set(parsedSuggestions)];

                useTimelineStore.getState().setNewSuggestions(dedupedSuggestions);
            },
        );

        const gaiaEventBusFetchAutoCompleteFailure = gaiaWsEventBus.on(
            "gaia_ws_fetch-auto-complete-suggestion-error",
            (response) => {
                logger.error({ response }, "GAIA autocomplete suggestion error");
            },
        );

        return () => {
            gaiaEventBusFetchAutoCompleteSuccess();
            gaiaEventBusFetchAutoCompleteFailure();
        };
    }, []);
};
