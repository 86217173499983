import { useEffect, useMemo, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { getLogger } from "@expert/logging";
import { GAIA_WS_EMITTER_NAME, gaiaWsEventBus } from "../gaiaEventBus";
import type {
    GaiaSubscription,
    GaiaSubscriptionBody,
    GaiaUnsubscription,
    GaiaWebSocketResponse,
    GaiaWebsocketContext,
} from "../types";
import { GaiaWebSocketEvent } from "../types";
import { useGaiaStatusEvents } from "./useGaiaStatusEvents";

const logger = getLogger({ module: "useGaiaWebsocket", tag: "solve" });

const heartbeatInterval = 60000;

export function useGaiaWebsocketInternal(baseUrl: string, application: string, token: string, identity: string) {
    const [loading, setLoading] = useState(true);
    const url = `${baseUrl}?application=${application}`;
    const { sendJsonMessage, readyState } = useWebSocket(
        url,
        {
            heartbeat: {
                interval: heartbeatInterval,
                message: JSON.stringify({
                    action: "heartbeat",
                    correlationId: identity,
                }),
            },
            shouldReconnect: (_closeEvent) => true,
            reconnectAttempts: 10,
            reconnectInterval: 2000,
            onMessage: createOnMessageEventHandler(setLoading),
            onError,
        },
        true,
    );

    useGaiaStatusEvents();

    useEffect(() => {
        if (!loading && readyState === ReadyState.CONNECTING) {
            logger.debug("GAIA | websocket connecting");
            setLoading(true);
        }
    }, [loading, readyState]);

    useEffect(() => {
        if (loading && readyState === ReadyState.OPEN) {
            logger.info("GAIA | websocket open");
            sendJsonMessage({
                action: "authorize",
                token,
                correlationId: identity,
            });
            logger.debug("GAIA | authorize message sent");
        }
    }, [loading, readyState, identity, token, sendJsonMessage]);

    useEffect(() => {
        if (!loading && readyState === ReadyState.CLOSED) {
            logger.info("GAIA | websocket closed");
            setLoading(false);
        }
    }, [loading, readyState]);

    const subscriptionResult: GaiaWebsocketContext = useMemo(() => {
        return {
            sendJsonMessage,
            loading,
            subscribeSessionToGaia: subscribeSessionToGaia(identity),
            unsubscribeSessionFromGaia: unsubscribeSessionFromGaia(identity),
        };
    }, [sendJsonMessage, loading, identity]);

    return subscriptionResult;
}

const createOnMessageEventHandler = (onAuthenticated: (isLoading: boolean) => void) => (event: MessageEvent) => {
    try {
        const data = JSON.parse(event.data as string) as GaiaWebSocketResponse;
        const { body } = data;

        getLoggerWithContext(body).debug({ body }, `GAIA | message received: ${GAIA_WS_EMITTER_NAME}_${data.name}`);
        gaiaWsEventBus.emit(`${GAIA_WS_EMITTER_NAME}_${data.name}`, data);
        if (data.name === GaiaWebSocketEvent.AuthorizationSuccess) {
            onAuthenticated(false);
        }
    } catch (err: unknown) {
        logger.error({ err, ...event }, "GAIA | message handler error");
    }
};

const onError = (event: Event) => {
    logger.error(event, "GAIA | websocket error");
};

export const subscribeSessionToGaia =
    (identity: string) =>
    ({ sessionId, partner, sendJsonMessage, callSid, timeout = 5000 }: GaiaSubscription) => {
        return new Promise<void>((resolve, reject) => {
            const loggerWithContext = logger.child({ sessionId, callSid });
            sendJsonMessage({
                action: "subscribe",
                sessionGroupId: sessionId,
                sessionId: callSid ?? sessionId,
                partner,
                correlationId: identity,
            });
            loggerWithContext.debug("GAIA | session subscribing");

            const timer = setTimeout(() => {
                cleanupEventListener();
                loggerWithContext.warn("GAIA | session subscription timeout");
                reject(Error("GAIA session subscription timed out"));
            }, timeout);

            const cleanupEventListener = gaiaWsEventBus.once("gaia_ws_session-subscription-success", () => {
                clearTimeout(timer);
                resolve();
            });
        });
    };

const unsubscribeSessionFromGaia =
    (identity: string) =>
    ({ sessionId, sendJsonMessage }: GaiaUnsubscription) => {
        sendJsonMessage({
            action: "unsubscribe",
            correlationId: identity,
            sessionId,
        });
        logger.child({ sessionId }).debug("GAIA | session unsubscribing");
    };

const getLoggerWithContext = (body: unknown) => {
    const { sessionGroupId, sessionId } = body as Partial<GaiaSubscriptionBody>;
    return (sessionGroupId ?? sessionId) ? logger.child({ sessionId, sessionGroupId }) : logger;
};
