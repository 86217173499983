import type { Features } from "@expert/shared-types";
import { useGaiaAutoCompleteEvents } from "./useGaiaAutoCompleteEvents";
import { useGaiaMessageEvents } from "./useGaiaMessageEvents";
import { useSdkEventBus } from "./useSdkEventBus";
import { useSolveSession } from "./useSolveSession";

export const useSetupSubscriptions = (features: Features) => {
    useGaiaMessageEvents();
    useGaiaAutoCompleteEvents();
    useSolveSession();
    useSdkEventBus(features);
};
