import { Flex, Text } from "@mantine/core";
import classes from "../PayDashboard.module.css";

interface PayLabel {
    payDenomination: string;
    salesPay: number;
    surveyPay: number;
}
export function PayLabel({ payDenomination, salesPay, surveyPay }: PayLabel) {
    if (salesPay === -1 && surveyPay === -1) {
        return (
            <Flex gap={16} align="flex-end" className={classes.headerText}>
                <Text className={classes.payTotal}>Missing data</Text>
            </Flex>
        );
    }
    if (salesPay === -1) {
        return (
            <Flex gap={16} align="flex-end" className={classes.headerText}>
                <Text className={classes.payTotal}>Missing data</Text>
                <Text className={classes.payBreakdown}>{`No sales data + ${payDenomination}${surveyPay} surveys`}</Text>
            </Flex>
        );
    }
    if (surveyPay === -1) {
        return (
            <Flex gap={16} align="flex-end" className={classes.headerText}>
                <Text className={classes.payTotal}>Missing data</Text>
                <Text className={classes.payBreakdown}>{`${payDenomination}${salesPay} sales + No survey data`}</Text>
            </Flex>
        );
    }
    return (
        <Flex gap={16} align="flex-end" className={classes.headerText}>
            <Text className={classes.payTotal}>{`${payDenomination}${salesPay + surveyPay}`}</Text>
            <Text
                className={classes.payBreakdown}
            >{`${payDenomination}${salesPay} sales + ${payDenomination}${surveyPay} surveys`}</Text>
        </Flex>
    );
}
