import type { Features } from "@expert/shared-types";

export const features: Features = {
    isProactiveEnabled: false,
    isOzmoToolEnabled: false,
    isOzmoMessagingEnabled: false,
    isMultiModalEnabled: false,
    isAutoCompleteEnabled: false,
    isReactiveCallContextEnabled: false,
    isMessageRatingEnabled: false,
    autoCompleteDelay: 500,
};
