export function GreenCheck() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" rx="10" fill="#20C997" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.8919 5.1148L7.66645 16.2546L3.56274 11.9377L4.7707 10.7894L7.58058 13.7453L15.6082 4.05176L16.8919 5.1148Z"
                fill="#0F1113"
            />
        </svg>
    );
}
