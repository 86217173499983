import { type GaiaWebSocketResponse, gaiaWsEventBus } from "@expert/gaia";
import { getLogger } from "@expert/logging";
import { useEffect } from "react";
import { type GaiaErrorMessage, type HistoricMessage, type MessageHistoryPayload } from "@expert/shared-types";
import { addMessages } from "../state";
import { type ExpertAssistMessage } from "../shared-types";

const logger = getLogger({ module: "FetchMessageHistory", tag: "solve" });
const NoMessageHistoryStatus = 404;

export const useFetchMessageHistoryEvents = () => {
    useEffect(() => {
        const fetchMessageHistorySuccess = gaiaWsEventBus.on(
            "gaia_ws_fetch-orchestrator-history-success",
            ({ body }: GaiaWebSocketResponse) => {
                const payload = body as Partial<MessageHistoryPayload>;
                const messages = payload.messages;
                if (messages && messages.length > 0) {
                    logger.debug(
                        { messageCount: messages.length, sessionGroupId: payload.sessionGroupId },
                        "GAIA | Successfully fetched message history",
                    );
                    addMessageHistoryToTimeline(messages);
                }
            },
        );

        const fetchMessageHistoryFailure = gaiaWsEventBus.on("gaia_ws_fetch-orchestrator-history-error", (response) => {
            const errorBody = response.body as Partial<GaiaErrorMessage>;
            if (errorBody.status === NoMessageHistoryStatus) {
                logger.debug({ response }, "GAIA | No message history for session");
            } else {
                logger.error({ response }, "GAIA | Fetch message history error");
            }
        });

        return () => {
            fetchMessageHistorySuccess();
            fetchMessageHistoryFailure();
        };
    }, []);
};

export const addMessageHistoryToTimeline = (messages: HistoricMessage[]) => {
    if (messages.length > 0) {
        const previousMessages = messages.map<ExpertAssistMessage>(
            ({ id, content, role, timestamp }: HistoricMessage) => ({
                text: content,
                type: role === "assistant" ? "Search" : "Expert",
                id,
                isUnread: false,
                timestamp,
            }),
        );
        addMessages(previousMessages);
    }
};
