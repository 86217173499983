import type { Features } from "@expert/shared-types";

// TODO: Use this for feature enablement instead of app name. This is dependent on gaia changes as well.
export const featuresMap: [keyof Features, string][] = [
    ["isOzmoMessagingEnabled", "expert-assist-ozmo"],
    ["isReactiveCallContextEnabled", "expert-assist-call-context"],
];

export const getAppName = ({ isReactiveCallContextEnabled, isOzmoMessagingEnabled }: Features) => {
    // eslint-disable-next-line no-nested-ternary
    return isReactiveCallContextEnabled
        ? "expert-assist-call-context"
        : isOzmoMessagingEnabled
          ? "expert-assist-ozmo"
          : "expert-assist";
};
