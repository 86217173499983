import { customCleanEnv, str, url } from "envalid";

interface Env {
    expertVoiceLink: string;
    versionNumber: string;
}

export const env = customCleanEnv(
    import.meta.env,
    {
        VITE_EXPERT_VOICE_LINK_URL: url(),
        VITE_VERSION: str({ default: undefined }),
    },
    (inputEnv): Env => ({
        expertVoiceLink: inputEnv.VITE_EXPERT_VOICE_LINK_URL,
        versionNumber:
            import.meta.env.MODE === "production"
                ? (inputEnv.VITE_VERSION ?? import.meta.env.MODE)
                : import.meta.env.MODE,
    }),
);
