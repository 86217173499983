import { FeedbackPortal } from "@soluto-private/expert-workspace-feedback";
import { getLogger } from "@expert/logging";
import { getSessionIdHistory, type TaskStatus } from "../sdk";
import { useFeedbackNotification } from "./hooks";

interface SidebarFeedbackButtonProps {
    feedbackPayload: {
        appName: string;
        sessionId: string;
        callSid: string | undefined;
        ssoAccessToken: string;
        partner: "verizon" | "att" | "cricket" | "asurion";
    };
    activeTaskStatus?: TaskStatus;
}

export function SidebarFeedbackButton({ activeTaskStatus, feedbackPayload }: SidebarFeedbackButtonProps) {
    const logger = getLogger({ module: "Workspace" });
    const showFeedbackNotification = useFeedbackNotification();
    const { sessionId, callSid } = feedbackPayload;

    return (
        <FeedbackPortal
            payload={{
                ...feedbackPayload,
                caller: "PlatformTimeline",
                extraData: {
                    "Previous Sessions": JSON.stringify(getSessionIdHistory(5)),
                },
            }}
            showNotification={showFeedbackNotification}
            taskStatus={activeTaskStatus}
            logger={logger.child({
                tag: "solve",
                sessionId,
                callSid,
            })}
            source="Sidebar"
        />
    );
}
