import { Button, Chip, Container, Flex, Group, ScrollArea, Stack, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { trackAppcuesEvent } from "@expert/monitoring";
import { useAnalytics } from "../../../../analytics";
import { IneligibleIcon, ListeningAnimation } from "../Icons";
import { useCurrentStepStore, useGaiaMessageStore, useHomeProductSessionStore } from "../../stores";
import { isVoiceTask, useMostRecentTask } from "../../../../sdk";
import classes from "./StepOneContent.module.css";
import { StepOneFooter } from "./StepOneFooter";

export interface ProductData {
    productType: string;
    detected: boolean;
}

export function StepOneContent() {
    const [itemLimit, setItemLimit] = useState<number | undefined>(7);
    const deviceEvents = useGaiaMessageStore((state) => state.devicesEvents);
    const homeProduct = useHomeProductSessionStore((state) => state.homeProduct);
    const { dispatcher } = useAnalytics();
    const updateStep = useCurrentStepStore((state) => state.updateStep);
    const [deviceDetectionData, setDeviceDetectionData] = useState<ProductData[]>([
        { productType: "Tablet", detected: false },
        { productType: "Smart watch", detected: false },
        { productType: "TV", detected: false },
        { productType: "Laptop", detected: false },
        { productType: "Gaming system", detected: false },
        { productType: "Router", detected: false },
        { productType: "Bluetooth speaker", detected: false },
        { productType: "Headphones", detected: false },
        { productType: "Computer keyboard", detected: false },
        { productType: "Security camera", detected: false },
        { productType: "Modem", detected: false },
        { productType: "Printer", detected: false },
        { productType: "Streaming device", detected: false },
        { productType: "Computer monitor", detected: false },
        { productType: "Computer", detected: false },
    ]);
    const activeTask = useMostRecentTask();
    if (activeTask && !isVoiceTask(activeTask)) {
        throw new Error("Unsupported task channel. SessionSummary should not be rendered without a voice task");
    }

    const detectedDevices = deviceDetectionData.filter((item) => item.detected);
    useEffect(() => {
        trackAppcuesEvent("EnhanceDeviceTagging shown", {});
    }, []);

    useEffect(() => {
        if (deviceEvents.length <= 0) return;
        const updatedDetectionData = deviceDetectionData.map((device) => {
            const located = deviceEvents.find(
                (item) =>
                    item.name.toLowerCase() === device.productType ||
                    item.name.toLowerCase().includes(device.productType.toLowerCase()),
            );
            if (located) {
                return { productType: device.productType, detected: true } as ProductData;
            }

            return device;
        });
        setDeviceDetectionData(updatedDetectionData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deviceEvents]);

    useEffect(() => {
        if (detectedDevices.length >= 3) {
            void dispatcher.dispatchBusinessEvent("DeviceDetectionAutoAdvance_GuidedFlow");
            const timer1 = setTimeout(() => {
                updateStep("SuggestedOfferStep");
            }, 2000);
            return () => {
                clearTimeout(timer1);
            };
        }
    }, [detectedDevices.length, dispatcher, updateStep]);

    if (!homeProduct) {
        return (
            <Flex className={classes.loadingContainer} data-testid="failure">
                <Container>{IneligibleIcon}</Container>
                <Text className={classes.statusBodyHeader}>We had an issue checking eligibility.</Text>
                <Text className={classes.statusBodyText} data-testid="sale-view-status-body">
                    Try refreshing the page.
                </Text>
            </Flex>
        );
    }

    const sendDeviceDetectionMessage = (action: string, device: string) => {
        void dispatcher.dispatchBusinessEvent("Device", {
            device,
            action,
        });
    };

    return (
        <Stack gap={0} className={classes.devicesSection}>
            <ScrollArea h="87%" mb="3rem">
                <Flex className={classes.deviceList}>
                    <Text fw="300" c="dark.0" size="24px">
                        Ask about their{" "}
                        <Text fw="700" c="success.5" size="24px" span>
                            home tech
                        </Text>
                    </Text>
                    <Flex mt="24px" gap={12} className={classes.listening}>
                        <ListeningAnimation />
                        <Text c="dark.2" fw="400" size="sm">
                            Listening for at least 3 devices...
                        </Text>
                    </Flex>
                    <Flex mt="24px" mb="2rem" className={classes.chipContainer}>
                        <Chip.Group
                            value={deviceDetectionData.filter((d) => d.detected).map((device) => device.productType)}
                            multiple
                        >
                            <Group gap={10}>
                                {deviceDetectionData
                                    .sort((a) => {
                                        if (a.detected) return -1;
                                        return 0;
                                    })
                                    .slice(0, itemLimit)
                                    .map((entry) => (
                                        <Chip
                                            id={`DeviceChip-${entry.productType}`}
                                            onClick={() => {
                                                if (entry.detected) {
                                                    void dispatcher.dispatchBusinessEvent("DeviceChipClicked", {
                                                        device: entry.productType,
                                                        selectionAction: entry.detected,
                                                    });
                                                }
                                                const updatedDeviceDetection = [...deviceDetectionData].map((item) => {
                                                    if (item.productType === entry.productType) {
                                                        if (item.detected) {
                                                            sendDeviceDetectionMessage("remove", item.productType);
                                                        } else sendDeviceDetectionMessage("add", item.productType);
                                                        return {
                                                            ...item,
                                                            detected: !item.detected,
                                                        };
                                                    }
                                                    return item;
                                                });
                                                setDeviceDetectionData(updatedDeviceDetection);
                                            }}
                                            icon={<svg />}
                                            variant="transparent"
                                            size="xl"
                                            key={entry.productType}
                                            value={entry.productType}
                                            classNames={{ label: classes.chip, iconWrapper: classes.chipIcon }}
                                        >
                                            {entry.productType}
                                        </Chip>
                                    ))}
                            </Group>
                        </Chip.Group>

                        {itemLimit !== undefined ? (
                            <Button
                                mt="16px"
                                variant="filled"
                                color="dark.4"
                                className={classes.showMore}
                                onClick={() => {
                                    void dispatcher.dispatchBusinessEvent("ShowMoreDeviceChipsClicked");
                                    setItemLimit(undefined);
                                }}
                            >
                                Show more
                            </Button>
                        ) : null}
                    </Flex>
                </Flex>
            </ScrollArea>
            <StepOneFooter />
        </Stack>
    );
}
