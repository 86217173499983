import { Container, Flex } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useAnalytics } from "../../../analytics";
import { useCurrentStepStore } from "../stores";
import { StepTwoContent } from "./StepSpecific/StepTwoContent";
import styles from "./GuidedFlow.module.css";
import { StepThreeContent } from "./StepSpecific/StepThreeContent";
import { UnlockAnimation } from "./StepSpecific/UnlockAnimation";
import type { SalesSteps } from "./types";
import { StepOneContent } from "./StepSpecific/StepOneContent";

export function GuidedFlow(): JSX.Element {
    const step: SalesSteps = useCurrentStepStore((state) => state.step);
    const [showAnimation, setShowAnimation] = useState(true);
    const { dispatcher } = useAnalytics();

    useEffect(() => {
        void dispatcher.dispatchBusinessEvent(`${step}_GuidedFlow`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    return (
        <Flex className={styles.stepsContainer}>
            <>
                {step === "DeviceCollectionStep" && <StepOneContent />}
                {step === "SuggestedOfferStep" && (
                    <>
                        {showAnimation ? (
                            <Container mt="2rem" display="flex" w="30%" h="30%">
                                <UnlockAnimation
                                    onComplete={() => {
                                        setShowAnimation(false);
                                    }}
                                />
                            </Container>
                        ) : (
                            <StepTwoContent />
                        )}
                    </>
                )}
                {step === "LegalDisclosureStep" && <StepThreeContent />}
            </>
        </Flex>
    );
}
