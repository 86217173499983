import { Flex, SimpleGrid, Text, Tooltip } from "@mantine/core";
import { type SalesChecklistBehaviors } from "@expert/shared-types";
import { GreenCheck, UncheckedRadioIcon } from "../Icons";
import { useOfferChecklistStore } from "../../stores/useOfferChecklistStore";
import { usePartner } from "../../../../sdk";
import { type Partner } from "../../../../shared-types";
import classes from "./PlanHighlights.module.css";

const behaviorMapping = (partner: Partner): { displayString: string; key: SalesChecklistBehaviors }[] => [
    {
        displayString: "Repair, replacement, or reimbursement for home tech",
        key: "PROTECTION",
    },
    {
        displayString: "24/7 premium tech support",
        key: "TECH_SUPPORT",
    },
    {
        displayString: "Covers future tech purchased—no receipts needed",
        key: "FUTURE_DEVICES",
    },
    {
        displayString: `Covers eligible non-${partner} devices`,
        key: "NON_VERIZON_DEVICES",
    },
];

export function PlanHighlights(): JSX.Element | null {
    const { data } = useOfferChecklistStore();
    const partner = usePartner();

    return (
        <Flex align="start" direction="column">
            <Text mb="10px" fw="var(--mantine-font-weight-normal)">
                Highlight these benefits
            </Text>
            {behaviorMapping(partner).map((item) => {
                return (
                    <SimpleGrid cols={1} className={classes.root} verticalSpacing="sm" key={item.key}>
                        <Tooltip
                            events={{ hover: !data[item.key], focus: !data[item.key], touch: !data[item.key] }}
                            label="We’ll check it off once you mention it"
                            position="left"
                            zIndex="var(--mantine-priority-high)"
                        >
                            <Flex className={`${data[item.key] ? classes.rowTrue : classes.rowFalse} ${classes.row}`}>
                                {data[item.key] ? <GreenCheck /> : <UncheckedRadioIcon />}
                                <Text className={classes.label}>{item.displayString}</Text>
                            </Flex>
                        </Tooltip>
                    </SimpleGrid>
                );
            })}
        </Flex>
    );
}
