import { AGENT_ACTIVITIES, type AgentActivity, isUnselectableActivity } from "../types";
import { useAgentStore } from "../store";
import { useAgentSdk } from "./useAgentSdk";
import { useAsyncLoading } from "./useLoading";

export function useAgentActivity() {
    const agentSdk = useAgentSdk();
    const { activity, setPendingActivity } = useAgentStore();

    async function setNewAgentActivity(localActivity: AgentActivity) {
        if (isUnselectableActivity(activity) && activity !== "Default") {
            setPendingActivity(localActivity);
        } else {
            await setAgentActivity(localActivity);
        }
    }
    const [loadingSetAgentActivity, setAgentActivity] = useAsyncLoading(async (selectedActivity: AgentActivity) => {
        await agentSdk.setAgentActivity(selectedActivity);
    });

    return { loadingSetAgentActivity, setNewAgentActivity, AGENT_ACTIVITIES };
}
