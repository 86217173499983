import type { GaiaSubscription, GaiaWebsocketContext } from "@expert/gaia";
import { useGaiaWebsocket } from "@expert/gaia";
import { getLogger } from "@expert/logging";
import { usePrevious } from "@mantine/hooks";
import {
    addMessage,
    formatTimestamp,
    getDefaultMessage,
    solveSessionClosed,
    solveSessionStarted,
} from "@soluto-private/expert-workspace-timeline";
import { useEffect } from "react";
import { retryAsync } from "ts-retry";
import { useSessionStore } from "../../../sdk/sessions/session.store";
import { type VoiceTask, useActiveTask, usePartner, useSession } from "../../../sdk";
import { formatTime } from "../../../shared-utils";

const subscriptionTimeoutDuration = 2000;
const subscriptionRetryCount = 3;

const logger = getLogger({
    module: "useSolveSession",
    tag: "solve",
});

interface SessionLifecycleProps {
    sessionId: string;
    websocketObj: GaiaWebsocketContext;
    withCustomer: boolean;
    partner: string;
    callSid?: string;
}

type SubscribeProps = {
    websocketObj: GaiaWebsocketContext;
} & Omit<GaiaSubscription, "sendJsonMessage" | "timeout">;

export const useSolveSession = () => {
    const { id: sessionId, kind: sessionKind } = useSession();
    const partner = usePartner();

    const previousSession = useSessionStore.getState().computed.previousSession();
    const isPreviousSessionWithCustomer = previousSession?.kind === "with-customer";
    const endedSessionStartTime = previousSession?.startedTimestamp;

    const websocketObj = useGaiaWebsocket();
    const task = useActiveTask();
    // TODO(afd): Revisit this to properly handle nonvoice tasks and not unsafely cast this
    // eslint-disable-next-line
    const callSid = (task as VoiceTask)?.agentCallId;
    const prevSessionId = usePrevious(sessionId);
    const previousCallSid = usePrevious(callSid);

    const previousWSLoading = usePrevious(websocketObj?.loading);

    useEffect(() => {
        if (!sessionId || !websocketObj || websocketObj.loading) return;

        const callSidChanged = callSid && previousCallSid !== callSid;
        const sessionIdChanged = prevSessionId !== sessionId;
        const websocketObjJustLoaded = previousWSLoading === true;
        const withCustomer = sessionKind === "with-customer";

        if (isPreviousSessionWithCustomer && sessionIdChanged && !withCustomer) {
            const sessionTimeInSeconds = endedSessionStartTime
                ? Math.floor((Date.now() - endedSessionStartTime) / 1000)
                : undefined;
            const formattedCallDuration = formatTime(sessionTimeInSeconds ?? 0, true);

            addMessage({
                ...getDefaultMessage("offCall"),
                id: `offCall_${crypto.randomUUID()}`,
                timestamp: formatTimestamp(),
                callDuration: formattedCallDuration,
                isUnread: true,
            });
        }

        if (sessionIdChanged && prevSessionId) {
            switchSession({
                partner,
                sessionId,
                websocketObj,
                callSid,
                withCustomer: sessionKind === "with-customer",
                prevSessionId,
            });
            return;
        }

        /** When a call comes, we subscribe to GAIA with a sessionId (callSid is undefined).
         * Once the callSid arrives, we need to resubscribe to GAIA with the existing sessionId and new callSid **/
        if (callSidChanged) return void resubscribeWithCallSid({ sessionId, websocketObj, callSid, partner });
        if (websocketObjJustLoaded)
            return void startSession({
                partner,
                sessionId,
                websocketObj,
                callSid,
                withCustomer,
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callSid, sessionId, websocketObj?.loading]);
};

const startSession = async ({ sessionId, websocketObj, withCustomer, callSid, partner }: SessionLifecycleProps) => {
    solveSessionStarted({ sessionId, callSid, withCustomer, partner });
    await subscribeSessionToGaia({ sessionId, callSid, websocketObj, partner });
};

const subscribeSessionToGaia = async ({ sessionId, callSid, partner, websocketObj }: SubscribeProps) => {
    const loggerWithContext = logger.child({
        sessionId,
        callSid,
    });
    try {
        await retryAsync(
            async () => {
                await websocketObj.subscribeSessionToGaia({
                    sessionId,
                    callSid,
                    partner,
                    sendJsonMessage: websocketObj.sendJsonMessage,
                    timeout: subscriptionTimeoutDuration,
                });
            },
            { delay: subscriptionTimeoutDuration, maxTry: subscriptionRetryCount },
        );
    } catch (e) {
        loggerWithContext.error(
            `Error subscribing session ${sessionId} to gaia with max retries (${subscriptionRetryCount})`,
            e,
        );
    }
};

const resubscribeWithCallSid = async ({ sessionId, websocketObj, callSid, partner }: SubscribeProps) => {
    await subscribeSessionToGaia({ sessionId, partner, websocketObj, callSid });
};

const switchSession = ({
    sessionId,
    websocketObj,
    withCustomer,
    callSid,
    partner,
    prevSessionId,
}: SessionLifecycleProps & { prevSessionId: string }) => {
    closeSession({ sessionId: prevSessionId, websocketObj, callSid, partner });
    void startSession({
        partner,
        sessionId,
        websocketObj,
        callSid,
        withCustomer,
    });
};

const closeSession = ({ sessionId, websocketObj, callSid, partner }: Omit<SessionLifecycleProps, "withCustomer">) => {
    solveSessionClosed({ sessionId, callSid, partner });
    websocketObj.unsubscribeSessionFromGaia({
        sessionId,
        sendJsonMessage: websocketObj.sendJsonMessage,
    });
};
