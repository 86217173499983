import { Button, Container, Flex } from "@mantine/core";
import { AnalyticsProvider } from "@soluto-private/eventualize-react";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";
import { useAnalytics, withPolymorphicClickAnalytics } from "../../../analytics";
import { useCallDetailsStore } from "../../../call-details";
import { useCustomerProgram, useVerifiedCustomerStore } from "../../../customer-info";
import { getCustomerDetails } from "../../../customer-info/api";
import { useGetPartnerPrograms, usePartnerFeatureConfigured } from "../../../partner/usePartnerConfig";
import { SalesFlow, useEligibilityV2 } from "../../../sales-tools";
import { LoadingAnimation } from "../../../sales-tools/home-product/components/Icons";
import { getSessionId, isVoiceTask, useMostRecentTask, usePartner, type VoiceTask } from "../../../sdk";
import { removeCountryCode, useFeatureFlag, validatePhoneNumber } from "../../../shared-utils";
import { env } from "./utils/environment";
import { CustomerNamesSection } from "./CustomerNamesSection";
import { CustomerProgramSelection } from "./CustomerProgramSelection";
import classes from "./CustomerVerificationUpdated.module.css";
import { SimplifiedMdnSection } from "./SimplifiedMdnSection";
import { useVerificationFlowStore } from "./store";
import { determineCustomerVerificationStatus } from "./utils/determineCustomerVerificationStatus";

export function CustomerVerificationFlowV3({ mdnFromTask }: { mdnFromTask: string }): JSX.Element {
    const ButtonWithAnalytics = withPolymorphicClickAnalytics(Button, "Click");
    const { data: isNewCustomerDetailsAPIEnabled, isLoading: isLoadingNewCustomerDetailsAPI } = useFeatureFlag(
        "Sales-isNewCustomerDetailsAPIEnabled",
    );
    const { dispatcher } = useAnalytics();
    const mostRecentTask = useMostRecentTask();
    const [stepHistory] = useVerificationFlowStore((state) => [state.stepHistory]);
    const currentStep = stepHistory.at(stepHistory.length - 1);
    const [mdn, setMdn] = useState<string | undefined>(mdnFromTask);
    const [customerName, setCustomerName] = useState<string | undefined>(undefined);
    const [isEditingSimplifiedMdn, setIsEditingSimplifiedMdn] = useState(true);
    const autoPopEnabled = usePartnerFeatureConfigured("autoPopEnabled");
    const partner = usePartner();
    const partnerSurveyEnabled = usePartnerFeatureConfigured("surveyEnabled");

    const programs = useGetPartnerPrograms();
    const { customerProgram } = useCustomerProgram();
    const showProgramSelection = partnerSurveyEnabled && programs.length > 1;

    if (!isVoiceTask(mostRecentTask)) {
        throw new Error("Active task is not voice task");
    }
    const [delayQuery, setDelayQuery] = useState(false);
    useTimer({
        expiryTimestamp: new Date(Date.now() + 10000),
        autoStart: true,
        onExpire: () => setDelayQuery(true),
    });

    const { data: customerData, isLoading } = useQuery({
        queryKey: [`customer-details-${mdn}`, mdn],
        queryFn: async () =>
            getCustomerDetails(mdn, partner, isNewCustomerDetailsAPIEnabled, isLoadingNewCustomerDetailsAPI),
        enabled: validatePhoneNumber(mdn).valid,
        refetchOnMount: true,
    });

    useEffect(() => {
        // if expert is coming back to change mdn/name, reset all the fields.
        // once there is a singular flow, we need to make this file less complex
        if (stepHistory.includes("VerificationComplete")) {
            setIsEditingSimplifiedMdn(true);
            setCustomerName(undefined);
        }
    }, [stepHistory]);

    const isEditingCustomerInformation = stepHistory.includes("VerificationComplete");
    useEligibilityV2(
        {
            asurionCallId: mostRecentTask.asurionCallId,
            partner,
            reservationSid: (mostRecentTask as VoiceTask & { config?: { reservation?: { sid: string } } }).config
                ?.reservation?.sid,
            sessionId: getSessionId(),
            customerMdn: mdn?.slice(2),
        },
        !!mdn && validatePhoneNumber(mdn).valid && delayQuery,
    );

    const onConfirmClick = () => {
        if (!customerName || !mdn) return;
        const { setCustomer } = useVerifiedCustomerStore.getState();
        const { pushStep } = useVerificationFlowStore.getState();

        setCustomer(
            {
                ...customerData?.primaryAccountHolder,
                techSavvyRating: customerData?.techSavvyRating,
                fullName: customerName,
                mdn,
            },
            customerData?.deviceActivation,
        );
        const callDetails = useCallDetailsStore.getState().callDetails;
        const formattedMdn = removeCountryCode(mdn);

        void dispatcher
            .withMeta({ Client: partner })
            .withExtra({
                planId: customerData?.primaryAccountHolder?.planId,
                RetailName: customerData?.primaryAccountHolder?.productName,
                AccountType: callDetails.ACCOUNT_TYPE,
                AccountCategory: callDetails.ACCOUNT_CATEGORY,
                EnrollmentStatus: callDetails.VALIDATION_FLAG,
                numPreviousDevices: customerData?.previousDevices?.length ?? 0,
                techSavvyRating: customerData?.techSavvyRating,
            })
            .withIdentities({
                MDN: formattedMdn,
                Email: customerData?.primaryAccountHolder?.email,
                AccountNumber: customerData?.primaryAccountHolder?.accountNumber,
            })
            .dispatchBusinessEvent("SupportedProfileDisplayed");
        void dispatcher
            .withMeta({ SkipMixpanel: true, SkipEDP: true, isTest: true, SkipOpenSearch: true })
            .withExtra({
                confirmedMdn: mdn,
                mdnFromTask,
            })
            .dispatchBusinessEvent("MdnConfirmedByExpert");
        if (!env.isDevMode && autoPopEnabled) {
            const authorizedUsers = Array.from(customerData?.authorizedUsers ?? []);
            if (customerData?.primaryAccountHolder) authorizedUsers.push(customerData.primaryAccountHolder);

            window.open(
                `${env.acssBaseUrl}${determineCustomerVerificationStatus(
                    customerName,
                    formattedMdn,
                    authorizedUsers,
                )}&mdnSearched=${formattedMdn}`,
                "_blank",
                "noopener,noreferrer",
            );
        }
        pushStep("VerificationComplete");
    };
    const compareName = (): string => {
        if (customerData?.primaryAccountHolder?.fullName === undefined) {
            return "NoCustomerFound";
        } else if (
            customerData.primaryAccountHolder.fullName === customerName ||
            customerData.authorizedUsers?.find((user) => user.fullName === customerName)
        ) {
            return "ExistingCustomerNameSelected";
        }
        return "NewCustomerNameAdded";
    };
    return (
        <AnalyticsProvider
            dispatcher={(dispatch) =>
                dispatch.withExtra({
                    newFlow: true,
                    page: currentStep,
                })
            }
        >
            {currentStep === "CustomerPhoneInput" && (
                <Flex id="customer-confirmation-flexbox" className={classes.root}>
                    <SimplifiedMdnSection
                        mdn={
                            isEditingCustomerInformation
                                ? (useVerifiedCustomerStore.getState().customer?.mdn ?? mdnFromTask)
                                : mdnFromTask
                        }
                        onComplete={(input) => {
                            if (input !== mdn) {
                                setCustomerName(undefined);
                            }
                            setMdn(input);
                        }}
                        onSave={(input) => {
                            setIsEditingSimplifiedMdn(input);
                        }}
                    />
                    {!isLoading ? (
                        <>
                            <CustomerNamesSection
                                key={mdn}
                                customer={customerData ?? undefined}
                                onOptionSelect={(input) => {
                                    setCustomerName(input);
                                }}
                                onMdnSave={mdn === ""}
                            />
                            {partnerSurveyEnabled && <CustomerProgramSelection />}
                        </>
                    ) : (
                        <Flex className={classes.animationRoot}>
                            <Container maw="160px">
                                <LoadingAnimation />
                            </Container>
                        </Flex>
                    )}
                    <ButtonWithAnalytics
                        size="md"
                        mt="24px"
                        analytics={{ actionId: "Confirm", extraContext: { customerNameSelected: compareName() } }}
                        onClick={onConfirmClick}
                        disabled={
                            !customerName ||
                            !validatePhoneNumber(mdn).valid ||
                            isEditingSimplifiedMdn ||
                            (partnerSurveyEnabled && showProgramSelection && !customerProgram)
                        }
                        data-testid="change-phone-number-button"
                    >
                        Confirm
                    </ButtonWithAnalytics>
                </Flex>
            )}
            {currentStep === "VerificationComplete" && <SalesFlow previousDevices={customerData?.previousDevices} />}
        </AnalyticsProvider>
    );
}
