import dayjs, { extend } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

export function formatUpdateTime(timestamp: string) {
    const date = dayjs(timestamp);
    extend(advancedFormat);
    return date.format("MMMM D, YYYY [at] h:mmA z");
}

export function formatPayPeriod(start: string) {
    const startDate = dayjs.utc(start);
    //depending on the data we receive, we may calculate the pay period based on the start number plus some offset in the future.
    const endDate = startDate.endOf("month");
    return `${startDate.format("MMMM DD")} - ${endDate.format("MMMM DD")}`;
}
