import { useEffect } from "react";
import { getLogger } from "@expert/logging";
import { useGaiaStore } from "../../store";
import { type GaiaSubscriptionBody } from "../types";
import { gaiaWsEventBus } from "../gaiaEventBus";

const logger = getLogger({ module: "useGaiaStatusEvents", tag: "solve" });

export const useGaiaStatusEvents = () => {
    const { setSubscriptionStatus, setIsWsAuthenticated, isWsAuthenticated } = useGaiaStore();

    useEffect(() => {
        const gaiaEventBusAuthSuccess = gaiaWsEventBus.on("gaia_ws_authorization-success", () => {
            if (!isWsAuthenticated) {
                setIsWsAuthenticated(true);
                logger.info("GAIA | websocket authorized");
            }
        });
        const gaiaEventBusAuthFailure = gaiaWsEventBus.on("gaia_ws_unauthorized-connection", ({ data, timestamp }) => {
            setIsWsAuthenticated(false);
            logger.error(
                { ...(data as unknown as object), responseTimestamp: timestamp },
                "GAIA | websocket unauthorized",
            );
        });
        return () => {
            gaiaEventBusAuthSuccess();
            gaiaEventBusAuthFailure();
        };
    }, [setIsWsAuthenticated, isWsAuthenticated]);

    useEffect(() => {
        const gaiaEventBusSubSuccess = gaiaWsEventBus.on("gaia_ws_session-subscription-success", (payload) => {
            const { sessionGroupId, sessionId } = payload.body as GaiaSubscriptionBody;
            const subscriptionKey = sessionGroupId ?? sessionId;
            if (subscriptionKey) {
                setSubscriptionStatus(subscriptionKey, "subscribed");
            }
        });
        const gaiaEventBusUnsubSuccess = gaiaWsEventBus.on("gaia_ws_session-unsubscription-success", (payload) => {
            const { sessionId } = payload.body as GaiaSubscriptionBody;
            if (sessionId) {
                setSubscriptionStatus(sessionId, "unsubscribed");
            }
        });
        return () => {
            gaiaEventBusSubSuccess();
            gaiaEventBusUnsubSuccess();
        };
    }, [setSubscriptionStatus]);
};
