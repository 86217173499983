import { Flex } from "@mantine/core";
import { env } from "../../../config";
import { AgentStatus, Profile } from "../../header";

export function HeaderControls(): JSX.Element {
    return (
        <Flex wrap="nowrap" gap="0">
            <AgentStatus />
            <Profile isProdMode={env.isProdMode} commitHash={env.commitHash} />
        </Flex>
    );
}
