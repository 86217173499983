import { useSolveGuideStore } from "@expert/solve-tools";
import { useEffect } from "react";
import { useDeviceActivationStore } from "../store";
import { useVerifiedCustomerStore } from "../../customer-info";
import { useFeatureFlag } from "../../shared-utils";

export const usePendingDeviceInfo = (isPhase1ConversationalIVR: boolean) => {
    const { data: isDeviceReplacementInfoEnabled } = useFeatureFlag("isDeviceReplacementInfoEnabled");
    const {
        device,
        pendingDevice,
        ivrInfo,
        setDeviceReplacement,
        reset: resetDeviceActivation,
    } = useDeviceActivationStore();
    const { deviceReplacement } = useVerifiedCustomerStore();
    const { setCurrentStepId, restartWithStep, reset: resetSolveGuide } = useSolveGuideStore();

    const hasDeviceInfo = Object.keys(device).length > 0;
    const isPendingDevice = Object.keys(pendingDevice).length > 0;

    useEffect(() => {
        if (!isDeviceReplacementInfoEnabled || !deviceReplacement) return;

        setDeviceReplacement(deviceReplacement);

        return resetDeviceActivation;
    }, [deviceReplacement, isDeviceReplacementInfoEnabled, resetDeviceActivation, setDeviceReplacement]);

    useEffect(() => {
        if (!isPhase1ConversationalIVR) return;
        if (!ivrInfo || isPendingDevice) return;

        setCurrentStepId("ShowConversationalIVRDetails");

        return resetSolveGuide;
    }, [ivrInfo, isPendingDevice, setCurrentStepId, resetSolveGuide, isPhase1ConversationalIVR]);

    useEffect(() => {
        if (!isPendingDevice || !hasDeviceInfo) return;

        restartWithStep("RestartWithNewInfo");

        return resetSolveGuide;
    }, [hasDeviceInfo, isPendingDevice, restartWithStep, resetSolveGuide]);
};
