import { ThemeIcon, useMantineTheme } from "@mantine/core";
import { Hangup, IconButton } from "../../../common-ui";
import { useAsyncLoading } from "../../../sdk";
import styles from "./Button.module.css";

interface RemoveParticipantButtonProps {
    onClick: () => Promise<void>;
    disabled?: boolean;
}

export function RemoveParticipantButton({ onClick, disabled }: RemoveParticipantButtonProps): JSX.Element {
    const theme = useMantineTheme();

    const [isLoading, removeFromCall] = useAsyncLoading(async () => {
        await onClick();
    });

    return (
        <IconButton
            analytics={{
                actionId: "RemoveParticipantButton",
            }}
            aria-label="Remove Participant"
            data-testid="call-controls-remove-participant"
            disabled={disabled}
            color={theme.colors.red[5]}
            loading={isLoading}
            onClick={removeFromCall}
            radius="xl"
            size="35"
            title="Remove caller"
            tooltip="Remove caller"
            variant="outline"
            classNames={{ root: styles.removeParticipantIconBtn }}
        >
            <ThemeIcon bottom={1} left={0} variant="transparent">
                <Hangup
                    height={16}
                    stroke={
                        disabled
                            ? "color-mix(in srgb, var(--mantine-color-dark-1), transparent 62%)"
                            : theme.colors.red[5]
                    }
                />
            </ThemeIcon>
        </IconButton>
    );
}
