import { useEffect } from "react";
import { useGaiaWebsocket } from "@expert/gaia";
import { getLogger } from "@expert/logging";
import type { Features } from "@expert/shared-types";
import { sdkEventBus, type VoiceTask } from "../../../sdk";
import { type NotifyCallContextPayload } from "../types";

interface ParseCallEventProps {
    event: VoiceTask;
    eventType: "start" | "end";
}

const logger = getLogger({ module: "useSdkEventBus", tag: "solve" });

export const useSdkEventBus = ({ isAutoCompleteEnabled }: Features) => {
    const websocketObj = useGaiaWebsocket();

    useEffect(() => {
        const callAcceptedEvent = sdkEventBus.on("call_accepted", (event) => {
            logger.info({ event }, "sdkEventBus | call accepted");

            if (isAutoCompleteEnabled && websocketObj && event.type === "voice") {
                const payload = parseCallEvent({ event, eventType: "start" });
                if (payload) {
                    websocketObj.sendJsonMessage(payload);
                    logger.info({ payload }, "notify call context sent to gaia");
                }
            }
        });

        const callDisconnectedEvent = sdkEventBus.on("task_completed", ({ task, reason }) => {
            logger.info({ task, reason }, "sdkEventBus | task_completed");

            if (isAutoCompleteEnabled && websocketObj && task.type === "voice") {
                const payload = parseCallEvent({ event: task as VoiceTask, eventType: "end" });
                if (payload) {
                    websocketObj.sendJsonMessage(payload);
                    logger.info({ payload }, "notify call context sent to gaia");
                }
            }
        });

        return () => {
            callAcceptedEvent();
            callDisconnectedEvent();
        };
    }, [websocketObj, isAutoCompleteEnabled]);
};

const parseCallEvent = ({ event, eventType }: ParseCallEventProps): NotifyCallContextPayload | undefined => {
    const { agentCallId, sessionId } = event;
    const callSid = agentCallId;

    if (!callSid || !sessionId) {
        logger.error({ event }, "callSid or sessionId is missing");
        return;
    }

    return {
        action: "notify-call-context",
        eventType,
        callSid,
        sessionId: callSid,
        sessionGroupId: sessionId,
        timestamp: new Date().toISOString(),
    };
};
