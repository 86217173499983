import { generateOzmoHomeUrl } from "@expert/solve-tools";
import type { Features } from "@expert/shared-types";
import { LaunchOzmoIcon, NotepadIcon, PhoneActivationIcon, ResetChatIcon } from "../../assets";
import type { GuideCommand } from "../../shared-types";
import { useTimelineStore } from "../../state";

export type ToolboxAction = "OpenNotepad" | "ResetChat" | "LaunchOzmo" | "OpenGuide";
export interface MenuItemUI {
    label: string;
    icon: React.JSX.Element;
    disabled: boolean;
    actionId: ToolboxAction;
    quickActionLabel?: string;
    tooltip?: string;
    additionalProps?: Record<string, unknown>;
}

export interface MenuItemProps {
    id: string;
    item: MenuItemUI;
    focusedMenuItem: string;
    onClick: (item: MenuItemUI) => void;
}

export const toolboxUIMapping = (features: Features, guide?: GuideCommand) => {
    const { callSid, partner, sessionId } = useTimelineStore.getState();

    return [
        {
            label: "Notepad",
            icon: <NotepadIcon />,
            quickActionLabel: "/n",
            disabled: false,
            actionId: "OpenNotepad",
        },
        {
            label: "Reset chat",
            icon: <ResetChatIcon />,
            quickActionLabel: "/r",
            disabled: false,
            actionId: "ResetChat",
            tooltip: "This’ll clear the chat",
        },
        {
            label: "Launch Ozmo",
            icon: <LaunchOzmoIcon />,
            quickActionLabel: features.isOzmoToolEnabled ? "/o" : undefined,
            disabled: !features.isOzmoToolEnabled,
            actionId: "LaunchOzmo",
            tooltip: features.isOzmoToolEnabled ? undefined : "Coming soon",
            additionalProps: features.isOzmoToolEnabled
                ? {
                      component: "a",
                      href: generateOzmoHomeUrl(sessionId, partner, callSid),
                      target: "_blank",
                  }
                : undefined,
        },
        guide && {
            icon: <PhoneActivationIcon />,
            actionId: "OpenGuide",
            ...guide,
        },
    ].filter((x) => !!x) as MenuItemUI[];
};
