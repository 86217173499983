import { getLogger } from "@expert/logging";
import { trackFullStoryEvent } from "@expert/monitoring";
import { useReactAnalytics } from "@soluto-private/eventualize-react";
import type { Features, SendReactiveMessagePayload } from "@expert/shared-types";
import { getAppName, useGaiaStore, useGaiaWebsocket } from "@expert/gaia";
import { addFailureMessage, addMessage } from "../state";
import type { SendMessageProps } from "../shared-types";

const logger = getLogger({ module: "sendMessage", tag: "solve" });

interface UseSendMessageProps {
    sessionId: string;
    callSid?: string;
    partner: string;
    expertId: string;
    lob?: string;
    features: Features;
}

export const useSendExpertAssistMessage = ({
    features,
    sessionId,
    callSid,
    partner,
    expertId,
    lob,
}: UseSendMessageProps) => {
    const websocketObj = useGaiaWebsocket();
    const { subscriptionStatus } = useGaiaStore();
    const { dispatcher } = useReactAnalytics();

    const getLoggerWithContext = () => {
        return logger.child({
            sessionId,
            callSid,
        });
    };

    const sendMessage = ({ id, text, type }: SendMessageProps) => {
        const messageId = id ?? crypto.randomUUID();
        const correlationId = crypto.randomUUID();
        const appName = getAppName(features);

        let payload: SendReactiveMessagePayload = {
            action: "orchestrator-invoke",
            message: text,
            messageId,
            partner,
            sessionId: callSid ?? sessionId,
            sessionGroupId: sessionId,
            userId: expertId,
            userType: "expert",
            correlationId,
            appName,
        };

        // TODO: mcafee should not break if we pass lob 🫠 dependency w/ gaia team
        if (partner !== "mcafee" && lob) {
            payload = { ...payload, lob };
        }

        addMessage({ id: messageId, text, type, isUnread: false });

        if (!websocketObj) {
            getLoggerWithContext().error({ payload, websocketObj }, "There is no websocket obj");
            const failureMessage = addFailureMessage("connectionFailure");
            void dispatcher.dispatchBusinessEvent("ErrorMessageReceived", {
                senderType: "system",
                messageType: failureMessage.type,
                messageId: failureMessage.id,
                message: failureMessage.text,
            });
            return;
        }

        if (subscriptionStatus[sessionId] !== "subscribed") {
            // TODO: Currently, this attempts to resubscribe + sends error message
            // Should we attempt to resubscribe + await + resend message?
            void websocketObj.subscribeSessionToGaia({
                sessionId,
                callSid,
                partner,
                sendJsonMessage: websocketObj.sendJsonMessage,
            });
            getLoggerWithContext().warn({ sessionId, callSid }, "Expert session not subscribed, subscribing now");
            const failureMessage = addFailureMessage("connectionFailure");
            void dispatcher.dispatchBusinessEvent("ErrorMessageReceived", {
                senderType: "system",
                messageType: failureMessage.type,
                messageId: failureMessage.id,
                message: failureMessage.text,
            });
            return;
        }
        websocketObj.sendJsonMessage(payload);
        void dispatcher.dispatchBusinessEvent("MessageSent", {
            senderType: "expert",
            messageType: type,
            messageId,
        });
        getLoggerWithContext().info({ payload }, "Message sent to gaia");
        trackFullStoryEvent("ExpertAssistMessageSent", { sessionId, callSid });
    };
    return sendMessage;
};
