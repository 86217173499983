import { type SalesLeaderboardEntry } from "../api/getCurrentSalesLeaderboard";

/*
 * We really want the top 3 but we are being asked to get the top 3 including all ties
 */
export const getTop3AndSelf = (
    currentEmployeeId: string,
    rankedLeaderboard: SalesLeaderboardEntry[],
): SalesLeaderboardEntry[] => {
    const topLeaderboard = rankedLeaderboard.slice(0, 3);
    // if employee is already part of top 3, just return top 3
    if (topLeaderboard.find((i) => i.employeeId === currentEmployeeId)) {
        return topLeaderboard;
    }

    //find employee in sorted leaderboard and push it and its position to the array
    const index = rankedLeaderboard.findIndex((e) => e.employeeId === currentEmployeeId);
    if (index === -1) {
        return topLeaderboard;
    }

    topLeaderboard.push(rankedLeaderboard[index]);
    return topLeaderboard;
};
