import { createContext, useContext } from "react";
import { useGaiaWebsocketInternal } from "./hooks/useGaiaWebsocket";
import type { GaiaWebsocketContext } from "./types";

export const GaiaContext = createContext<GaiaWebsocketContext | null>(null);
interface GaiaWebsocketProviderProps {
    url: string;
    application: "expert-workspace" | "expert-assist";
    token: string;
    identity: string;
    children?: React.ReactNode;
}

export function GaiaWebsocketProvider({ url, application, token, identity, children }: GaiaWebsocketProviderProps) {
    const webSocketObject = useGaiaWebsocketInternal(url, application, token, identity);
    return <GaiaContext.Provider value={webSocketObject}>{children}</GaiaContext.Provider>;
}

export function useGaiaWebsocket() {
    const context = useContext(GaiaContext);
    return context;
}
