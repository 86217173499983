import { getLogger } from "@expert/logging";
import { api } from "../../shared-utils";
import { type CctsContact, type Contact, getContactPartner, TRANSFER_OPTIONS } from "../../shared-types";

export const contactsApi = api.extend(({ prefixUrl }) => ({ prefixUrl: `${prefixUrl}/api/vdn-contacts/v1` }));
const logger = getLogger({
    module: "getContactDetails",
});

export const getContacts = async (): Promise<Contact[]> => {
    try {
        // TODO: We're in a migration phase. After BE changes the array type will be removed
        const response = await contactsApi.get(`contacts`).json<CctsContact[] | { contacts: CctsContact[] }>();
        const contacts = Array.isArray(response) ? response : response.contacts;

        // Transform the contacts by adding default values for group and isInternal
        const normalizedContacts: Contact[] = contacts.map(
            (contact: CctsContact) =>
                ({
                    name: contact.friendlyName,
                    number: contact.destination,
                    vdn: contact.vdn,
                    group: contact.group ?? "Unlisted", // Default group for vdn's being fetched from contacts api
                    partner: getContactPartner(contact),
                    isInternal: true,
                }) as Contact,
        );

        // Combine TRANSFER_OPTIONS with unlisted contacts
        const combinedContacts: Contact[] = [...TRANSFER_OPTIONS, ...normalizedContacts];
        return combinedContacts;
    } catch (err: unknown) {
        logger.error({ err }, `getContacts API call failed with exception. Only known transfer options returned`);
        return TRANSFER_OPTIONS;
    }
};
