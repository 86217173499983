import { Container, Flex, Text } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import { GreenQuote } from "../Icons";
import { useVerifiedCustomerStore } from "../../../../customer-info";
import { useFeatureFlag } from "../../../../shared-utils";
import { useGaiaMessageStore } from "../../stores";
import classes from "./TransitionStatements.module.css";

interface TextMapFields {
    statement: StatementMap[][];
}

interface StatementMap {
    message?: string;
    format?: string;
    deviceNames?: boolean;
}

const statementMapVMPCustomer: Record<string, TextMapFields> = {
    withoutDevices: {
        statement: [
            [
                {
                    message:
                        "We have another plan, separate from your phone insurance that could be a great fit for you.",
                },
            ],
            [
                {
                    message:
                        "I’m glad we were able to work through this issue. Before we wrap up, I would like to share with you coverage separate from your phone insurance that could be a great fit for you.",
                },
            ],
            [
                {
                    message:
                        "To make sure you have the support you need, I’d like to share some coverage separate from your phone insurance that could be a great fit for you.",
                },
            ],
        ],
    },
    withDevices: {
        statement: [
            [
                {
                    message:
                        "We have another plan, separate from your phone insurance that’ll cover your home tech like your ",
                },
                {
                    message: "$DEVICES$.",
                    format: "highlight",
                    deviceNames: true,
                },
            ],
            [
                {
                    message:
                        "Now that we have finished working through your device issue, you shared other home tech, like your ",
                },
                {
                    message: "$DEVICES$.",
                    format: "highlight",
                    deviceNames: true,
                },
                {
                    message:
                        " We have coverage separate from your phone insurance that seems like a great fit for you.",
                },
            ],
        ],
    },
};

const statementMapNonVMPCustomer: Record<string, TextMapFields> = {
    withoutDevices: {
        statement: [
            [
                {
                    message:
                        "I’m glad we were able to work through this issue. Before we wrap up, I would like to share with you coverage that could be a great fit for you.",
                },
            ],
        ],
    },
    withDevices: {
        statement: [
            [
                {
                    message:
                        "Now that we’ve resolved your issue and you don’t have any questions, you mentioned some other tech you have in your home, like your",
                },
                {
                    message: "$DEVICES$.",
                    format: "highlight",
                    deviceNames: true,
                },
                {
                    message: " We have coverage that could be a great fit for you.",
                },
            ],
            [
                {
                    message:
                        "Now that we have finished working through your device issue, you shared other tech you have in your home, like your ",
                },
                {
                    message: "$DEVICES$.",
                    format: "highlight",
                    deviceNames: true,
                },
                {
                    message: " We have coverage that seems like a great fit for you.",
                },
            ],
        ],
    },
};

const statementMapGeneric: Record<string, TextMapFields> = {
    default: {
        statement: [
            [
                {
                    message:
                        "We have another plan, separate from your phone insurance, that covers eligible tech in your home.",
                },
            ],
        ],
    },
};

function getRandomStatement(scenarioTexts: TextMapFields): StatementMap[] {
    const randomIndex = Math.floor(Math.random() * scenarioTexts.statement.length);
    return scenarioTexts.statement[randomIndex];
}

export function TransitionStatements(): JSX.Element | null {
    const { data: isIntegratedFlowEnabled = false, isLoading: isLoadingIntegratedFlow } =
        useFeatureFlag("EWP-isIntegratedFlowEnabled");
    const { data: isDynamicChecklistEnabled = false, isLoading: isLoadingDynamicChecklist } = useFeatureFlag(
        "Sales-isDynamicChecklistEnabled",
    );
    const [customer] = useVerifiedCustomerStore((state) => [state.customer]);
    const deviceEvents = useGaiaMessageStore((state) => state.devicesEvents);

    const deviceList = useMemo(() => deviceEvents.map((msg) => msg.name), [deviceEvents]);
    const [transitionStatement, setTransitionStatement] = useState<StatementMap[] | null>(null);
    useEffect(() => {
        if (
            (isIntegratedFlowEnabled && !isLoadingIntegratedFlow) ||
            (isDynamicChecklistEnabled && !isLoadingDynamicChecklist)
        ) {
            setTransitionStatement(statementMapGeneric.default.statement[0]);
        } else {
            if (transitionStatement && deviceList.length > 1) return;
            const statement =
                deviceList.length === 0
                    ? getRandomStatement(
                          customer?.productName
                              ? statementMapVMPCustomer.withoutDevices
                              : statementMapNonVMPCustomer.withoutDevices,
                      )
                    : getRandomStatement(
                          customer?.productName
                              ? statementMapVMPCustomer.withDevices
                              : statementMapNonVMPCustomer.withDevices,
                      );
            setTransitionStatement(statement);
        }
    }, [
        customer?.productName,
        deviceList,
        transitionStatement,
        isIntegratedFlowEnabled,
        isLoadingIntegratedFlow,
        isDynamicChecklistEnabled,
        isLoadingDynamicChecklist,
    ]);
    const lastDevice = deviceList[deviceList.length - 1];
    const deviceString =
        deviceList.length < 2 ? deviceList : `${deviceList.slice(0, -1).join(", ")} and ${lastDevice}.`;
    return (
        <Flex className={classes.text}>
            <Container p={0}>
                <GreenQuote />
            </Container>
            <Text c="dark.1" size="sm" pl={12}>
                {transitionStatement?.map((item) => {
                    const classNames = item.format === "highlight" ? classes.highlight : "";

                    return (
                        <Text size="sm" fw="400" span className={classNames} key={item.message}>
                            {item.deviceNames ? deviceString : item.message}
                        </Text>
                    );
                })}
            </Text>
        </Flex>
    );
}
