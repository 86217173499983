export type TrainingStep = "in-progress" | "starting" | "high-volume-cancellation" | "time-limit-met-cancellation";
export type TrainingStatus = "complete" | "incomplete" | undefined;
export type TrainingEvent =
    | "training.timeout"
    | "training.started"
    | "training.completed"
    | "training.timelimitmet"
    | "training.ejected.completed"
    | "training.ejected"
    | "training.ejected.inactivity"
    | "training.ejected.inactivity.completed"
    | "training.ejected.lostfocus"
    | "training.ejected.lostfocus.completed"
    | "training.completed.tabclose";

/** milliseconds */
export const DEFAULT_TRAINING_MODAL_TIMEOUT = 30_000;

export function convertMinutesToMilliseconds(minutes: number) {
    return minutes * 60_000;
}

export function openTraining(links: string[] | undefined): boolean {
    return links?.every((link) => window.open(link)) ?? false;
}

export function playAudio(src: string, { volume = 0.6 } = {}) {
    const audio = new Audio(src);
    audio.volume = volume;
    void audio.play();
}
