import { type Logger } from "@expert/logging";
import { type VoiceTask, type usePartner } from "../../sdk";
import { useFeatureFlag } from "../../shared-utils";
import { useVerifiedCustomerStore } from "../../customer-info";
import { useDeviceActivationGuide } from "./useDeviceActivationGuide";
import { useConversationalIVR } from "./useConversationalIVR";
import { useActivationGuideDisclosure } from "./useActivationGuideDisclosure";
import { useAutomaticActivationGuide } from "./useAutomaticActivationGuide";
import { useGuideCommand } from "./useGuideCommand";
import { usePendingDeviceInfo } from "./usePendingDeviceInfo";

interface SetupActivationGuideProps {
    // Session/task
    isCustomerSession: boolean;
    partner: ReturnType<typeof usePartner>;
    activeVoiceTask: VoiceTask | null;

    // Other
    logger: Logger;
}

export const useSetupActivationGuide = ({
    isCustomerSession,
    partner,
    activeVoiceTask,
    logger,
}: SetupActivationGuideProps) => {
    const { data: isDeviceActivationGuideEnabled } = useFeatureFlag("isDeviceActivationGuideEnabled");
    const { data: isConversationalIVREnabled } = useFeatureFlag("isConversationalIVRGuideEnabled");
    const { customer } = useVerifiedCustomerStore();

    const isActivationGuideEnabled = !!isDeviceActivationGuideEnabled || !!isConversationalIVREnabled;
    const isPhase1ConversationalIVR = !isDeviceActivationGuideEnabled && !!isConversationalIVREnabled;
    const isCustomerVerified = !!customer;

    const { isGuideOpen, openGuide, closeGuide } = useActivationGuideDisclosure(logger, activeVoiceTask?.agentCallId);
    const guide = useDeviceActivationGuide();

    useConversationalIVR(!!isConversationalIVREnabled, activeVoiceTask?.customerOriginalCallId);
    usePendingDeviceInfo(isPhase1ConversationalIVR);
    useAutomaticActivationGuide({
        isActivationGuideEnabled,
        isPhase1ConversationalIVR,
        isCustomerSession,
        partner,
        supportNumberCalled: activeVoiceTask?.supportNumberCalled,
        isCustomerVerified,
        isGuideOpen,
        openGuide,
        closeGuide,
    });

    const guideCommand = useGuideCommand({
        isActivationGuideEnabled,
        isPhase1ConversationalIVR,
        isCustomerSession,
        isCustomerVerified,
        openGuide,
    });

    return {
        isActivationGuideEnabled,
        guide,
        isGuideOpen,
        guideCommand,
        closeGuide,
    };
};
