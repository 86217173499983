import { Button, Group, ThemeIcon } from "@mantine/core";
import { withPolymorphicClickAnalytics } from "../../../../analytics";
import { ArrowLeft } from "../../../../common-ui";
import { useAsyncLoading, useCallbackState } from "../../../../sdk";
import { useControlsStore } from "../../../CallControls/controls.store";
import { useCallbacksFormStore } from "../../callbacks.store";
import { type CallbackFormVariant } from "../../types";
import { useScheduleCallback } from "../../useScheduleCallback";

export function CallbackActions({
    onBackClick,
    onSubmit,
    formVariant,
    isSubmitting,
}: {
    onBackClick?: () => void;
    formVariant: CallbackFormVariant;
    onSubmit: () => Promise<void>;
    isSubmitting: boolean;
}) {
    const callbackState = useCallbackState();
    const { closeCallbackPanel } = useControlsStore();
    const { callbackType, selectedCallbackTime, selectedCallbackNowDelay } = useCallbacksFormStore();
    const { cancelCallback, canScheduleOrUpdateCallback } = useScheduleCallback();

    const [isCancellingCallback, asyncCancelCallback] = useAsyncLoading(cancelCallback);

    const shouldShowUpdateButtons = callbackState && formVariant !== "post-call";

    const AnalyticsButton = withPolymorphicClickAnalytics(Button, "Click");

    return (
        <Group id="callback-actions" justify="flex-end">
            {(callbackState ?? onBackClick) ? (
                <AnalyticsButton
                    data-testid="callback-form-back-button"
                    analytics={{
                        actionId: "Back_CloseScheduleCallback",
                    }}
                    title="Back"
                    onClick={onBackClick ? onBackClick : closeCallbackPanel}
                    color="gray.0"
                    variant="subtle"
                    leftSection={
                        <ThemeIcon bottom={1} variant="transparent">
                            <ArrowLeft />
                        </ThemeIcon>
                    }
                >
                    Back
                </AnalyticsButton>
            ) : null}

            {shouldShowUpdateButtons ? (
                <AnalyticsButton
                    data-testid="callback-form-cancel-button"
                    analytics={{
                        actionId: "CancelScheduleCallback",
                    }}
                    title="Cancel callback"
                    disabled={isCancellingCallback}
                    loading={isCancellingCallback}
                    onClick={asyncCancelCallback}
                    color="gray.0"
                    variant="outline"
                >
                    Cancel callback
                </AnalyticsButton>
            ) : null}

            <AnalyticsButton
                data-testid="call-controls-callback-form-schedule-or-update-button"
                analytics={{
                    actionId: "ScheduleCallback",
                    eventData: {
                        callbackType,
                        callbackTime: selectedCallbackTime,
                        callbackDelay: selectedCallbackNowDelay,
                    },
                }}
                title={shouldShowUpdateButtons ? "Update callback" : "Schedule callback"}
                disabled={!canScheduleOrUpdateCallback || isSubmitting}
                loading={isSubmitting}
                onClick={onSubmit}
                variant="filled"
            >
                {shouldShowUpdateButtons ? "Update" : "Schedule"}
            </AnalyticsButton>
        </Group>
    );
}
