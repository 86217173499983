import type { ButtonProps } from "@mantine/core";
import { Button, ThemeIcon } from "@mantine/core";
import { withPolymorphicClickAnalytics } from "../../../analytics";
import { Plus } from "../../../common-ui";
import { useAgentSdk, useAsyncLoading, type VoiceTask } from "../../../sdk";
import { withControlsLock } from "../controls.store";
import { type Contact, US } from "../../../shared-types";
import styles from "./Button.module.css";

interface AddParticipantButtonProps extends ButtonProps {
    task: VoiceTask;
    transferVdn?: Contact | null;
    transferTfn?: string | null;
    disabled: boolean;
    isPrimary?: boolean;
}
export function AddParticipantButton({
    task,
    transferVdn,
    transferTfn,
    disabled,
    isPrimary = false,
    ...props
}: AddParticipantButtonProps): JSX.Element {
    const agentSdk = useAgentSdk();
    const [loading, handleAddParticipant] = useAsyncLoading(
        withControlsLock(async () => {
            if (transferVdn) {
                // Passing transferVdn.destination without stringifying it
                // somehow breaks the add-conference call
                await agentSdk.addConferenceParticipant(
                    task,
                    transferVdn.number.toString(),
                    transferVdn.number.toString(),
                    transferVdn.isInternal,
                );
            } else {
                if (!transferTfn) {
                    throw new Error("No TFN provided");
                }
                //TODO: handle passing in primary flag
                await agentSdk.addConferenceParticipant(task, `${US}${transferTfn}`, `${US}${transferTfn}`);
                //FIXME: remove this delay when we get conferencing timing right, this button isn't really used in ways where the delay will have a negative effect
                //eslint-disable-next-line no-promise-executor-return
                await new Promise((r) => setTimeout(r, 3000));
            }
        }),
    );

    const AddParticipantBtn = withPolymorphicClickAnalytics(Button, "Click");

    return (
        <AddParticipantBtn
            analytics={{
                actionId: "AddParticipant",
                extraContext: { isPrimary },
                eventData: {},
            }}
            classNames={{ section: styles.buttonSections }}
            data-testid="call-controls-add-participant"
            leftSection={
                <ThemeIcon autoContrast bottom={1} variant="transparent">
                    <Plus
                        height={13}
                        stroke={disabled || loading ? "var(--mantine-color-gray-6)" : "var(--mantine-color-gray-0)"}
                    />
                </ThemeIcon>
            }
            loading={loading}
            disabled={disabled || loading}
            onClick={handleAddParticipant}
            variant="filled"
            {...props}
        >
            Add to call
        </AddParticipantBtn>
    );
}
