import { Badge, Button, Flex, Group, Image, Radio, Space, Text, Textarea } from "@mantine/core";
import { type Dispatch, useEffect, useState } from "react";
import { getLogger } from "@expert/logging";
import LevitatingDevices from "../../common-ui/assets/image/LevitatingDevices.svg";
import { useToast } from "../../common-ui";
import { type TrainingTask, useAgentSdk, useAgentStore } from "../../sdk";
import { expertWorkspaceWebSocketEventBus } from "../../websocket";
import { useFeatureFlag } from "../../shared-utils";
import {
    convertMinutesToMilliseconds,
    DEFAULT_TRAINING_MODAL_TIMEOUT,
    openTraining,
    type TrainingStep,
    type TrainingStatus,
    type TrainingEvent,
    playAudio,
} from "./utilities";
import styles from "./trainingModal.module.css";
import { TrainingCountdownText } from "./TrainingCountdownText";
import { useTrainingStateStore } from "./training.store";
import { type CancelTrainingPayload, sendTrainingAnalytic, type TrainingAnalyticRequest } from "./api";

const logger = getLogger({ module: "SharedModal" });

export interface SharedModalProps {
    badgeTitle: string;
    trainingLinks?: string[];
    showBadgeMinutesRemaining: boolean;
    badgeMinutes: number;
    timerExpirationTimestamp: number;
    trainingName: string;
    trainingNotes: string;
    trainingStarted: boolean;
    includeSubmitTimer: boolean;
    task: TrainingTask;
    completeTask: () => void;
}

export function SharedModal({
    badgeTitle,
    trainingLinks,
    showBadgeMinutesRemaining,
    badgeMinutes,
    timerExpirationTimestamp,
    trainingName,
    trainingNotes,
    trainingStarted,
    includeSubmitTimer,
    task,
    completeTask,
}: SharedModalProps): JSX.Element {
    const { trainingStep, setTrainingStep, setExpirationTimestamp } = useTrainingStateStore();
    const { activity } = useAgentStore();
    const [trainingStatus, setTrainingStatus] = useState<TrainingStatus>();
    const toast = useToast();

    useEffect(() => {
        const unsubscribe = expertWorkspaceWebSocketEventBus.on(
            "ws_training-event",
            (payload: CancelTrainingPayload) => {
                if (payload.action !== "eject" || payload.reason.toLowerCase() !== "high-call-volume") return;
                setTrainingStep("high-volume-cancellation");
                playAudio("/assets/audio/training-timeout.mp3");
                setExpirationTimestamp(Date.now() + DEFAULT_TRAINING_MODAL_TIMEOUT);
                unsubscribe();
            },
        );

        return () => {
            unsubscribe();
        };
    }, [setExpirationTimestamp, setTrainingStep]);

    const handleTimeOut = () => {
        if (!trainingStatus) {
            setTrainingStatus("incomplete");
        }
        logger.info(trainingName, "Timer expired during training.");
        setTrainingStep("time-limit-met-cancellation");
        playAudio("/assets/audio/training-timeout.mp3");
        setExpirationTimestamp(Date.now() + DEFAULT_TRAINING_MODAL_TIMEOUT);
    };

    async function sendAnalyticsEvent(eventType: TrainingEvent) {
        const request: TrainingAnalyticRequest = {
            workerActivityName: activity,
            taskSid: task.id,
            eventType,
        };

        try {
            await sendTrainingAnalytic(request);
        } catch (err) {
            logger.error(err, "Training analytic event failed to send");
        }
    }

    const launchTraining = () => {
        const trainingOpenedSuccess = openTraining(trainingLinks);
        if (!trainingOpenedSuccess) {
            toast.error("The training tab was blocked from opening - check your browser settings and try again");
            logger.error(trainingLinks, "Could not open training links.");
        } else {
            logger.info(trainingLinks, "Opened training links.");
        }
    };

    const startTraining = () => {
        launchTraining();

        setTrainingStep("in-progress");
        setExpirationTimestamp(Date.now() + convertMinutesToMilliseconds(badgeMinutes));
        void sendAnalyticsEvent("training.started");
    };

    return (
        <Flex direction="row" h="100%" justify="center">
            <Flex className={styles.modalContainer} direction="column" maw="450" pr="0.5rem" mr="2rem">
                <TrainingBadges
                    badgeTitle={badgeTitle}
                    badgeMinutes={badgeMinutes}
                    timeStarted={timerExpirationTimestamp}
                    showBadgeMinutesRemaining={showBadgeMinutesRemaining}
                    trainingStarted={trainingStarted}
                    onTimerExpired={handleTimeOut}
                />
                <Text fw="var(--mantine-font-weight-light)" fz="2.3rem" pb="2rem">
                    {trainingName}
                </Text>
                <Text fz="sm" mb="2rem">
                    {trainingNotes}
                </Text>
                {trainingStarted ? (
                    <TrainingFeedback
                        trainingStep={trainingStep}
                        includeSubmitTimer={includeSubmitTimer}
                        timerExpirationTimestamp={timerExpirationTimestamp}
                        trainingStatus={trainingStatus}
                        setTrainingStatus={setTrainingStatus}
                        completeTask={completeTask}
                        relaunchTraining={launchTraining}
                        trainingName={trainingName}
                        sendAnalyticsEvent={sendAnalyticsEvent}
                    />
                ) : (
                    <Group justify="end">
                        <Button color="var(--mantine-color-primary-6)" onClick={() => startTraining()}>
                            <Text fz="sm">Start Training</Text>
                            {includeSubmitTimer ? (
                                <>
                                    <Space w="xs" />
                                    <TrainingCountdownText
                                        appendUnit={false}
                                        format="m:ss"
                                        ff="monospace"
                                        timeExpired={startTraining}
                                        timeStarted={timerExpirationTimestamp}
                                    />
                                </>
                            ) : null}
                        </Button>
                    </Group>
                )}
            </Flex>
            <Group maw="600" miw="400" w="100%" className={styles.TrainingImg}>
                <Image src={LevitatingDevices} alt="Levitating devices" />
            </Group>
        </Flex>
    );
}

interface TrainingFeedbackProps {
    trainingStep: TrainingStep;
    includeSubmitTimer: boolean;
    timerExpirationTimestamp: number;
    trainingStatus: TrainingStatus;
    trainingName: string;
    setTrainingStatus: Dispatch<React.SetStateAction<TrainingStatus>>;
    completeTask: () => void;
    relaunchTraining: () => void;
    sendAnalyticsEvent: (eventType: TrainingEvent) => Promise<void>;
}

function TrainingFeedback({
    trainingStep,
    includeSubmitTimer,
    timerExpirationTimestamp,
    trainingStatus,
    trainingName,
    setTrainingStatus,
    completeTask,
    relaunchTraining,
    sendAnalyticsEvent,
}: TrainingFeedbackProps): JSX.Element {
    const [agentPartner] = useAgentSdk().getPartners();
    const [feedback, setFeedback] = useState<string>("");
    const { data: isTrainingFeedbackEnabled } = useFeatureFlag("isTrainingFeedbackEnabled", {
        partner: agentPartner,
    });

    const handleSubmitFeedback = () => {
        const trainingCompleteEvent =
            trainingStep === "high-volume-cancellation" ? "training.ejected.completed" : "training.completed";
        const trainingIncompleteEvent =
            trainingStep === "high-volume-cancellation" ? "training.ejected" : "training.timelimitmet";

        void sendAnalyticsEvent(trainingStatus === "complete" ? trainingCompleteEvent : trainingIncompleteEvent);

        completeTask();

        if (feedback.trim() !== "") {
            // NOTE: It would be ideal to encode these components using something like new URLSearchParams(...), however
            //       that will encode the URL components space characters as `+`s. This will then break the message when
            //       it's opened in the email client because the space characters will all show up as `+`s. Since that's
            //       the case, we manually encode the components here.
            const urlEncodedFeedback = encodeURIComponent(feedback);
            const urlEncodedSubject = encodeURIComponent(`${trainingName} - Expert Workspace Feedback`);
            window.location.href = `mailto:TTD-support@asurion.com?subject=${urlEncodedSubject}&body=${urlEncodedFeedback}`;
        }
    };

    return (
        <>
            <Text size="sm" pb="sm">
                Did you complete the training?
            </Text>
            <Group pb="sm">
                <Radio
                    checked={trainingStatus === "complete"}
                    classNames={{ label: styles.Radio }}
                    label="Yes"
                    value="complete"
                    onChange={() => setTrainingStatus("complete")}
                />
                <Radio
                    checked={trainingStatus === "incomplete"}
                    classNames={{ label: styles.Radio }}
                    label="No"
                    value="incomplete"
                    onChange={() => setTrainingStatus("incomplete")}
                />
            </Group>
            {isTrainingFeedbackEnabled ? (
                <>
                    <Textarea
                        placeholder="Additional feedback"
                        pb="0.25rem"
                        rows={7}
                        value={feedback}
                        onChange={(event) => setFeedback(event.currentTarget.value)}
                    />
                    <Text c="var(--mantine-color-dark-3)" fz="xs" pb="2rem">
                        Feedback will be sent to TTD-support@asurion.com
                    </Text>
                </>
            ) : null}
            <Group justify="end">
                <Button color="var(--mantine-color-primary-4)" variant="outline" onClick={relaunchTraining}>
                    Relaunch training
                </Button>
                <Button
                    className={styles.Button}
                    color="var(--mantine-color-primary-6)"
                    disabled={!trainingStatus}
                    onClick={() => handleSubmitFeedback()}
                >
                    <Text fz="sm">Submit</Text>
                    {includeSubmitTimer ? (
                        <>
                            <Space w="xs" />
                            <TrainingCountdownText
                                appendUnit={false}
                                format="m:ss"
                                ff="monospace"
                                timeExpired={handleSubmitFeedback}
                                timeStarted={timerExpirationTimestamp}
                            />
                        </>
                    ) : null}
                </Button>
            </Group>
        </>
    );
}

interface TrainingBadgesProps {
    badgeTitle: string;
    showBadgeMinutesRemaining: boolean;
    trainingStarted: boolean;
    timeStarted: number;
    badgeMinutes: number;
    onTimerExpired: () => void;
}

function TrainingBadges({
    badgeTitle,
    showBadgeMinutesRemaining,
    trainingStarted,
    timeStarted,
    badgeMinutes,
    onTimerExpired,
}: TrainingBadgesProps): JSX.Element {
    const timeUnits = Number(badgeMinutes) === 1 ? "minute" : "minutes";

    return (
        <Group pb="xs">
            <Badge
                autoContrast
                className={styles.Badge}
                color="var(--mantine-color-dark-4)"
                fw="var(--mantine-font-weight-normal)"
                fz="xs"
                radius="xs"
                style={{ "--data-text-color": "var(--mantine-color-dark-1)" }}
                tt="none"
            >
                {badgeTitle}
            </Badge>
            {showBadgeMinutesRemaining ? (
                <Badge
                    autoContrast
                    className={styles.Badge}
                    color="var(--mantine-color-success-5)"
                    fw="var(--mantine-font-weight-normal)"
                    fz="xs"
                    radius="xs"
                    style={{ "--data-text-color": "var(--mantine-color-dark-9)" }}
                    tt="none"
                >
                    {trainingStarted ? (
                        <>
                            <TrainingCountdownText
                                appendUnit={false}
                                format="m:ss"
                                ff="monospace"
                                mr="0.25rem"
                                size="xs"
                                timeExpired={onTimerExpired}
                                timeStarted={timeStarted}
                            />
                            remaining
                        </>
                    ) : (
                        `${badgeMinutes} ${timeUnits}`
                    )}
                </Badge>
            ) : null}
        </Group>
    );
}
