import { Button, Stack } from "@mantine/core";
import { useAnalytics } from "../../../../analytics";
import { RightArrowIcon } from "../Icons";
import { useCurrentStepStore } from "../../stores";
import classes from "./StepOneContent.module.css";

export function StepOneFooter(): JSX.Element | null {
    const [step, updateStep] = useCurrentStepStore((state) => [state.step, state.updateStep]);
    const { dispatcher } = useAnalytics();

    return (
        <Stack className={classes.footerBox}>
            <Button
                className={classes.stepAdvance}
                variant="subtle"
                color="white"
                data-testid="devices-asked-button"
                rightSection={<RightArrowIcon />}
                onClick={() => {
                    updateStep("SuggestedOfferStep");
                    void dispatcher.withExtra({ step }).dispatchBusinessEvent("ButtonClick_DevicesAsked");
                }}
            >
                Show Offer
            </Button>
        </Stack>
    );
}
