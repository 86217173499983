import { type DeviceReplacement } from "../customer-info";

export type ActivationState = "NotStarted" | "InProgress" | "Success" | "Failure";
export type DeviceSource = "AsurionClaims" | "Verizon" | "ThirdParty";

export interface DeviceInfo extends Partial<DeviceReplacement> {
    previousModel?: string;
    source?: DeviceSource;
}

export interface IvrInfo {
    details: string;
    alreadyTried: string;
}

export const modelItems = [
    { label: "iPhone 14 or newer", actionId: "iPhone14OrNewer" },
    { label: "iPhone 13 or older", actionId: "iPhone13OrOlder" },
    { label: "Samsung", actionId: "Samsung" },
    { label: "Other", actionId: "Other" },
];

export const IMEIMask = "000-000-000-000-000";
export const ICCIDMask = "00000000000000000[0000]A";
export const MDNMask = "+{1} (000) 000-0000";
export const DeviceActivationFlowId = "DeviceActivation";
export const DeviceActivationFirstStepId = "StartDeviceActivation";
