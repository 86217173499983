import { getLogger } from "@expert/logging";
import { AnalyticsProvider, useReactAnalytics } from "@soluto-private/eventualize-react";
import { type GuideCommand, Timeline, useSendExpertAssistMessage } from "@soluto-private/expert-workspace-timeline";
import type { Features } from "@expert/shared-types";
import { type VoiceTask, useActiveTask, usePartner, useSessionId } from "../sdk";
import { useFeatureFlag, userCache } from "../shared-utils";
import { useSetupSubscriptions } from "./state";
import { features } from "./utils";

const logger = getLogger({
    rootModule: "PlatformTimeline",
    tag: "solve",
});

export function PlatformTimeline({ guide, isFocused }: { guide: GuideCommand; isFocused: boolean }): JSX.Element {
    const task = useActiveTask();
    // TODO(afd): Revisit this to properly handle nonvoice tasks and not unsafely cast this
    // eslint-disable-next-line
    const callSid = (task as VoiceTask)?.agentCallId;
    const expertId = userCache.employeeId ?? "";
    const expertName = userCache.givenName ?? "";
    const sessionId = useSessionId();
    const partner = usePartner();

    const { data: isProactiveEnabled } = useFeatureFlag("isProactiveEnabled");
    const { data: isOzmoToolEnabled } = useFeatureFlag("isOzmoToolEnabled", { partner });
    const { data: isOzmoMessagingEnabled } = useFeatureFlag("isOzmoMessagingEnabled", { partner });
    const { data: isReactiveCallContextEnabled } = useFeatureFlag("isReactiveCallContextEnabled", { partner });
    const { data: isAutoCompleteEnabled } = useFeatureFlag("isAutoCompleteEnabled");
    const { data: autoCompleteDelay } = useFeatureFlag<number>("autoCompleteDelay");
    const { data: isMessageRatingEnabled } = useFeatureFlag("isMessageRatingEnabled");

    const updatedFeatures: Features = {
        ...features,
        isOzmoToolEnabled: isOzmoToolEnabled ?? features.isOzmoToolEnabled,
        isProactiveEnabled: isProactiveEnabled ?? features.isProactiveEnabled,
        isOzmoMessagingEnabled: isOzmoMessagingEnabled ?? features.isOzmoMessagingEnabled,
        isAutoCompleteEnabled: isAutoCompleteEnabled ?? features.isAutoCompleteEnabled,
        isReactiveCallContextEnabled: isReactiveCallContextEnabled ?? features.isReactiveCallContextEnabled,
        autoCompleteDelay: autoCompleteDelay ?? features.autoCompleteDelay,
        isMessageRatingEnabled: isMessageRatingEnabled ?? features.isMessageRatingEnabled,
    };

    useSetupSubscriptions(updatedFeatures);

    const sendMessage = useSendExpertAssistMessage({
        features: updatedFeatures,
        sessionId,
        callSid,
        expertId,
        partner,
    });

    const { dispatcher } = useReactAnalytics();
    const enhancedDispatcher = dispatcher
        .withIdentities({
            ...(expertId && { ExpertId: expertId }),
            ...(callSid && { CallSid: callSid }),
        })
        .withExtra({ tool: "expert-assist" });

    return (
        <AnalyticsProvider dispatcher={enhancedDispatcher}>
            <Timeline
                expertId={expertId}
                expertName={expertName}
                callSid={callSid}
                features={updatedFeatures}
                sendMessage={sendMessage}
                sessionId={sessionId}
                partner={partner}
                guide={guide}
                isFocused={isFocused}
                logger={logger.child({
                    sessionId,
                    callSid,
                })}
            />
        </AnalyticsProvider>
    );
}
