/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Flex } from "@mantine/core";
import { useEffect } from "react";
import { useAnalytics } from "../../../analytics";
import { useHomeProductSessionStore } from "../stores";
import { EligibilityStatus, SaleProgress } from "../types";
import { useFeatureFlag } from "../../../shared-utils";
import { SaleView } from "./StepSpecific/SaleView";
import classes from "./HomeProductFlow.module.css";
import { GuidedFlow } from "./GuidedFlow";
import { EnrollFailureScreen } from "./StepSpecific/EnrollFailure";
import { IntegratedFlow } from "./IntegratedFlow";

export function HomeProductFlow(): JSX.Element {
    const { dispatcher } = useAnalytics();
    const { data: isIntegratedFlowEnabled, isLoading: isLoadingIntegratedFlow } =
        useFeatureFlag("EWP-isIntegratedFlowEnabled");
    const { data: isDynamicChecklistEnabled, isLoading: isLoadingDynamicChecklist } = useFeatureFlag(
        "Sales-isDynamicChecklistEnabled",
    );

    const homeProduct = useHomeProductSessionStore((state) => state.homeProduct);

    useEffect(() => {
        void dispatcher.dispatchAction("PageShown", "sale-tool");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!homeProduct) {
        return <SaleView status={EligibilityStatus.Unknown} />;
    }

    return homeProduct.status === EligibilityStatus.Eligible && homeProduct.saleProgress === SaleProgress.InProgress ? (
        <Flex className={classes.flexContainer}>
            {(!isLoadingIntegratedFlow && isIntegratedFlowEnabled) ||
            (!isLoadingDynamicChecklist && isDynamicChecklistEnabled) ? (
                <IntegratedFlow />
            ) : (
                <GuidedFlow />
            )}
        </Flex>
    ) : (
        <Flex className={classes.flexContainer} data-testid="home-product-flow-container">
            {/* Customer Not eligible */}
            {homeProduct.status !== EligibilityStatus.Eligible &&
            homeProduct.saleProgress === SaleProgress.InProgress ? (
                <SaleView status={homeProduct.status} />
            ) : null}

            {/* Endgame */}
            {homeProduct.saleProgress === SaleProgress.Success || homeProduct.saleProgress === SaleProgress.Declined ? (
                <SaleView status={homeProduct.saleProgress} />
            ) : null}

            {/* Alternate Endgame */}
            {homeProduct.saleProgress === SaleProgress.Failure ? <EnrollFailureScreen /> : null}
        </Flex>
    );
}
