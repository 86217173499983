import type { Draft } from "immer";
import { current } from "immer";
import { getLogger } from "@expert/logging";
import { type Partner } from "../../shared-types";
import { useAgentStore } from "../agent/store";
import { type TaskCancelledReason, type AgentSdkBase, type Task, type TaskCompletedReason } from "../agent";
import { isTrainingTask } from "../agent/trainingTask";
import { isOutboundVoiceTask, isVoiceTask } from "../agent/voice";
import { sdkEventBus } from "../agent/eventBus";
import { subTaskCompletedCallbackNow } from "../agent/callbacks";
import { onSessionEnd, onSessionStart } from "../../utils/setupSessionLifecycleManager";
import { SessionInstance } from "./session";
import type { CustomerSessionEndedReason, CustomerSessionStartedReason, Session } from "./types";
import type { SessionStore } from "./session.store";
import { updateTaskStatusInternal, useSessionStore } from "./session.store";

interface SessionStartArgs {
    currentSessionId: string;
    reason: CustomerSessionStartedReason;
    task: Task;
    agentSdk: AgentSdkBase;
}

interface SessionEndArgs {
    currentSessionId: string;
    reason: CustomerSessionEndedReason;
    triggeringTask: Task | undefined;
    currentPartner: Partner;
    agentSdk: AgentSdkBase;
}

interface SessionControlsState {
    sessionStartArgs: SessionStartArgs | undefined;
    sessionEndArgs: SessionEndArgs | undefined;
}

interface SessionControls {
    setSessionStartArgs: (args: SessionStartArgs) => void;
    setSessionEndArgs: (args: SessionEndArgs) => void;
}

const logger = getLogger({
    module: "sessionsOrchestrator",
});

const tryCatch = (fn: () => void) => {
    try {
        fn();
    } catch (err) {
        logger.error({ err }, `Error in session orchestrator: ${(err as Error).message}`);
    }
};

const setUpSessionControlsWrapper = () => {
    const state: SessionControlsState = {
        sessionStartArgs: undefined,
        sessionEndArgs: undefined,
    };

    const sessionControls: SessionControls = {
        setSessionStartArgs: (args: SessionStartArgs) => {
            state.sessionStartArgs = args;
            logger.debug(
                {
                    sessionStartArgs: args,
                },
                "Deferring session start",
            );
        },
        setSessionEndArgs: (args: SessionEndArgs) => {
            state.sessionEndArgs = args;
            logger.debug(
                {
                    sessionEndArgs: args,
                },
                "Deferring session end",
            );
        },
    };

    return { sessionControlsState: state, sessionControls };
};

const cleanupSessionControlsWrapper = (sessionControlsState: SessionControlsState) => {
    if (sessionControlsState.sessionStartArgs) {
        triggerCxSessionStart(sessionControlsState.sessionStartArgs);
    } else if (sessionControlsState.sessionEndArgs) {
        triggerCxSessionEnd(sessionControlsState.sessionEndArgs);
    }
};

const withSessionControlsCleanup = (fn: (sessionControls: SessionControls) => void) => {
    const { sessionControlsState, sessionControls } = setUpSessionControlsWrapper();

    tryCatch(() => {
        fn(sessionControls);
    });

    cleanupSessionControlsWrapper(sessionControlsState);
};

const initSessions = (agentSdk: AgentSdkBase, tasks: Task[]) => {
    withSessionControlsCleanup((sessionControls) => {
        useSessionStore.getState().mutateStore((state) => {
            return initStore(sessionControls, state, tasks, agentSdk);
        });
    });
};

const addNewAgentTask = (task: Task, agentSdk: AgentSdkBase) => {
    withSessionControlsCleanup((sessionControls) => {
        if (isVoiceTask(task)) {
            const currentSession = useSessionStore.getState().computed.currentSession();

            // Ad-hoc outbound call, new session
            if (task.callDirection === "outbound" && !task.previousTaskId) {
                sessionControls.setSessionStartArgs({
                    currentSessionId: currentSession.id,
                    reason: "AdHocOutboundCall",
                    task,
                    agentSdk,
                });
            } else if (task.callDirection === "inbound") {
                // New incoming call, new session
                sessionControls.setSessionStartArgs({
                    currentSessionId: currentSession.id,
                    reason: "InboundCall",
                    task,
                    agentSdk,
                });
            } else {
                useSessionStore.getState().addTaskToSession(currentSession.id, task);
            }
            return;
        }

        if (isTrainingTask(task)) {
            const currentSession = useSessionStore.getState().computed.currentSession();
            // New training, new session
            sessionControls.setSessionStartArgs({
                currentSessionId: currentSession.id,
                reason: "IncomingTraining",
                task,
                agentSdk,
            });
            return;
        }

        throw new Error("Unsupported task type");
    });
};

const onTaskRejected = (task: Task, agentSdk: AgentSdkBase) => {
    tryCatch(() => {
        const currentSession = useSessionStore.getState().computed.currentSession();

        triggerCxSessionEnd({
            currentSessionId: currentSession.id,
            reason: "CallRejected",
            triggeringTask: task,
            currentPartner: task.partner,
            agentSdk,
        });
    });
};

const onTaskCancelled = (task: Task, agentSdk: AgentSdkBase, reason: TaskCancelledReason) => {
    withSessionControlsCleanup((sessionControls) => {
        useSessionStore.getState().mutateStore((state) => {
            const currentSession = state.sessions[state.sessions.length - 1];

            const storeTask = state.getTask(task.id);
            if (!storeTask) throw new Error(`Task ${task.id} not found in store`);

            updateTaskStatusInternal(state, task.id, "completed");

            const endSessionReason: CustomerSessionEndedReason =
                reason === "TechnicalIssues" ? "TechnicalIssues" : "CallCompletedWithoutCallback";

            sessionControls.setSessionEndArgs({
                currentSessionId: currentSession.id,
                reason: endSessionReason,
                triggeringTask: storeTask,
                currentPartner: storeTask.partner,
                agentSdk,
            });

            if (reason === "TechnicalIssues") {
                sdkEventBus.emit("technical_issues", true);
            }
            return state;
        });
    });
};

const onTaskCompleted = (task: Task, reason: TaskCompletedReason, agentSdk: AgentSdkBase) => {
    withSessionControlsCleanup((sessionControls) => {
        useSessionStore.getState().mutateStore((state) => {
            const currentSession = state.sessions[state.sessions.length - 1];

            const storeTask = state.getTask(task.id);
            if (!storeTask) throw new Error(`Task ${task.id} not found in store`);

            updateTaskStatusInternal(state, task.id, "completed");

            const endSessionReason: CustomerSessionEndedReason =
                reason === "TechnicalIssues" ? "TechnicalIssues" : "CallCompletedWithoutCallback";

            if (reason !== "CallbackInitiated") {
                sessionControls.setSessionEndArgs({
                    currentSessionId: currentSession.id,
                    reason: endSessionReason,
                    triggeringTask: storeTask,
                    currentPartner: storeTask.partner,
                    agentSdk,
                });
            }

            if (reason === "TechnicalIssues") {
                sdkEventBus.emit("technical_issues", true);
            }
            return state;
        });
    });
};

/** Manually end an active session in cases where there will be no task actions to trigger from */
const completeSession = (agentSdk: AgentSdkBase, partner: Partner) => {
    tryCatch(() => {
        const currentSession = useSessionStore.getState().computed.currentSession();
        logger.trace({ session: currentSession }, "completing session");

        if (currentSession.currentTask) throw new Error("Cannot manually complete a session that has active task(s)");
        if (currentSession.callbackState?.callbackType === "CallbackNow")
            throw new Error("Cannot manually complete a session that is waiting callback");

        triggerCxSessionEnd({
            currentSessionId: currentSession.id,
            reason: "CallCompletedWithoutCallback",
            triggeringTask: undefined,
            currentPartner: partner,
            agentSdk,
        });
    });
};
/** Manually cleanup an active session from storage in cases where there will be no task actions to trigger from */
const cleanupPersistedSession = (agentSdk: AgentSdkBase, partner: Partner) => {
    tryCatch(() => {
        const currentSession = useSessionStore.getState().computed.currentSession();

        triggerCxSessionEnd({
            currentSessionId: currentSession.id,
            reason: "CallCompletedWithoutCallback",
            triggeringTask: undefined,
            currentPartner: partner,
            agentSdk,
        });
    });
};

const initStore = (
    sessionControls: SessionControls,
    state: Draft<SessionStore>,
    tasks: Task[] | undefined | null,
    agentSdk: AgentSdkBase,
) => {
    const [partner] = agentSdk.getPartners();
    if (state.initialized) throw new Error("SessionStore already initialized");

    const handleExistingCallbackNow = (session: Draft<SessionInstance>) => {
        logger.debug({ session: current(session) }, "Handling existing callback now");
        // If we refresh after scheduling a callback now, event listeners are lost, re-listen
        if (session.currentTask && session.callbackState?.callbackType === "CallbackNow") {
            subTaskCompletedCallbackNow(session.currentTask.id);
        }
    };

    // To achieve the requirement that we do not expose tasks till post SDK/Twilio init we hide tasks till SDK init
    state.sessions.forEach((session) => {
        session.restoreHydratedTasks();
    });

    let currentSession = state.sessions[state.sessions.length - 1];
    const agentHasTasks = !!tasks?.length;

    logger.debug(
        {
            agentHasTasks,
            agentTasks: tasks,
            currentSession: current(currentSession),
        },
        "Initializing Cx session store",
    );

    // Session's active task is the same as agent active task, replace the non-class instance in the session with our instance Task
    if (agentHasTasks && currentSession.currentTask?.id === tasks[0].id) {
        currentSession.replaceTask(tasks[0]);
        handleExistingCallbackNow(currentSession);
        state.initialized = true;
        return state;
    }

    const handleAgentHasTasks = () => {
        // Satisfies the type checker
        if (!tasks) throw new Error("Unexpected undefined tasks");

        // We have tasks assigned, but current session has no task or has a different task association than the current task
        if (currentSession.currentTask?.id !== tasks[0].id) {
            // New task was created from an outbound call from this session, just add task to session and break
            if (isOutboundVoiceTask(tasks[0]) && tasks[0].sessionId === currentSession.id) {
                currentSession.addTask(tasks[0]);
                return;
            }
            sessionControls.setSessionStartArgs({
                currentSessionId: currentSession.id,
                reason: "CallAlreadyExists",
                task: tasks[0],
                agentSdk,
            });
        }
    };

    const handleAgentDoesNotHaveTasks = () => {
        // We are waiting on an outbound call for this session, don't end the session
        if (currentSession.callbackState?.callbackType === "CallbackNow") {
            return;
        }

        // We have existing wrapping state, defer to handlers of wrapping state to wrap or end session
        if (currentSession.wrappingState) {
            return;
        }

        if (currentSession.kind === "with-customer") {
            // Session is with a customer (on task), Agent has no tasks, and we are not waiting for a callback now, therefore we must end the session
            // Regardless of if the session has or does not have an active task association
            sessionControls.setSessionEndArgs({
                currentSessionId: currentSession.id,
                reason: "CallUnexpectedlyEnded",
                triggeringTask: undefined,
                currentPartner: partner,
                agentSdk,
            });
        }
    };

    if (agentHasTasks) {
        handleAgentHasTasks();
    } else {
        handleAgentDoesNotHaveTasks();
    }

    // Current session may be different after the above checks
    currentSession = state.sessions[state.sessions.length - 1];

    // Cleanup all other sessions that are not the current session
    state.sessions.map((session) => {
        if (session.id === currentSession.id || session.metadata.status === "ended") return;

        session.endSession("ForcedSessionEnd", undefined);

        return null;
    });

    handleExistingCallbackNow(currentSession);

    // Regression can land us in an untenable state where we have no active tasks, a pending callback, but no set scheduled time
    // This will cause the UI to skip past rendering anything callback-state related, and the agent will be semi-stuck in this situation
    // After a reload, we fix this state directly to ensure a refresh clears the problem
    if (
        !currentSession.currentTask &&
        currentSession.callbackState?.callbackType === "CallbackNow" &&
        !currentSession.callbackState.scheduledFor
    ) {
        const delay = currentSession.callbackState.callbackDelay ?? 0;
        currentSession.callbackState.scheduledFor = Date.now() + delay * 1000;
    }

    state.initialized = true;

    return state;
};

const triggerCxSessionStart = ({ currentSessionId, reason, task }: SessionStartArgs) => {
    logger.debug({ reason, currentSessionId, task }, "Starting Cx session");

    if (!isVoiceTask(task) && !isTrainingTask(task)) {
        throw new Error("Unsupported task type");
    }

    const newSession = new SessionInstance({
        /* sessionId is set in Twilio task attributes after the task (ExWo's one) is created.
           Due to the fact that we have auto-accept there is a race condition between updating task attributes and accepting it.
           We need to have sessionId available at earlier stages to avoid this race condition.
           So, we add task attribute sessionIdFallback in reservationCreated event handler before creating ExWo task.
           And then, we use it as a fallback in certain places when there is a chance that sessionId hasn't been set yet.
        */
        id: task.sessionIdFallback,
        previousId: currentSessionId,
        startedTimestamp: Date.now(),
        kind: task.hasCustomer ? "with-customer" : "without-customer",
        tasks: [task],
        callbackState: null,
        wrappingState: null,
        metadata: {
            startReason: reason,
            status: "active",
        },
    });

    useSessionStore.getState().endSession(currentSessionId, reason, newSession);

    const previousSession = useSessionStore.getState().getSession(currentSessionId);
    if (!previousSession) throw new Error(`Session ${currentSessionId} not found`);

    void onSessionStart(newSession as Session, task.partner);
    sdkEventBus.emit("session_changed", {
        newSession: newSession as Session,
        previousSession,
        reason,
    });
};

const triggerCxSessionEnd = ({
    currentSessionId,
    reason,
    triggeringTask,
    currentPartner,
    agentSdk,
}: SessionEndArgs) => {
    logger.debug({ reason, currentSessionId, triggeringTask }, "Ending Cx session");

    const newSession = new SessionInstance({
        id: crypto.randomUUID(),
        previousId: currentSessionId,
        startedTimestamp: Date.now(),
        kind: "without-customer",
        tasks: [],
        callbackState: null,
        wrappingState: null,
        metadata: {
            startReason: reason,
            status: "active",
        },
    });

    useSessionStore.getState().endSession(currentSessionId, reason, newSession);

    const previousSession = useSessionStore.getState().getSession(currentSessionId);
    if (!previousSession) throw new Error(`Session ${currentSessionId} not found`);

    if (
        reason === "CallCancelled" ||
        reason === "CallCompletedWithoutCallback" ||
        reason === "CallRejected" ||
        reason === "TechnicalIssues"
    ) {
        void handleEndSessionSetAgentActivity(reason, agentSdk);
    }

    const previousSessionPartner = currentPartner;
    onSessionEnd(previousSession, previousSessionPartner, newSession as Session);
    sdkEventBus.emit("session_changed", {
        newSession: newSession as Session,
        previousSession,
        reason,
    });
};

const handleEndSessionSetAgentActivity = async (reason: CustomerSessionEndedReason, agentSdk: AgentSdkBase) => {
    const pendingActivity = useAgentStore.getState().pendingActivity;
    logger.debug({ pendingActivity, reason }, "==== TROUBLESHOOT: session ended pending activity and reason ====");

    // NOTE: We should never get any string other than the ones defined in the AgentActivity type, but it looks
    //       like somehow we're sometimes getting the string '"undefined"' (the quotes included). The following
    //       if check is there to confirm whether this is happening and report it when it does happen.

    // @ts-expect-error Checking for invalid value
    // eslint-disable-next-line prefer-smart-quotes/prefer
    if (pendingActivity === '"undefined"') {
        logger.warn(
            `==== TROUBLESHOOT: pending activity wrong status ====: Somehow pendingActivity was the string undefined: '${pendingActivity}`,
        );
    }

    const activeTasks = useSessionStore.getState().computed.activeTasks();
    if (reason === "TechnicalIssues") {
        await agentSdk.setAgentActivity("Technical Issues");
    } else if (pendingActivity) {
        await agentSdk.setAgentActivity(pendingActivity);
    } else if (!activeTasks.length) {
        // If we have an active task, we do NOT want to go available, such as during a callback
        await agentSdk.setAgentActivity("Available");
    }
};

export const sessionsOrchestrator = {
    initSessions,
    addNewAgentTask,
    onTaskRejected,
    onTaskCancelled,
    onTaskCompleted,
    completeSession,
    cleanupPersistedSession,
};
