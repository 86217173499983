import type { GaiaWebSocketResponse } from "@expert/gaia";
import { gaiaWsEventBus } from "@expert/gaia";
import { getLogger } from "@expert/logging";
import { useEffect } from "react";
import type {
    ReactiveMessagePayload,
    ProactiveMessagePayload,
    GaiaErrorBody,
    GaiaErrorMessage,
} from "@expert/shared-types";
import { addErrorMessage, handleIncomingMessage } from "@soluto-private/expert-workspace-timeline";
import { triggerProactiveSound, triggerTabNotification } from "@expert/proactive-notifications";
import { useFeatureFlag } from "../../../shared-utils";
import { features } from "../../utils";
import { isProactiveErrorMessage, isProactiveMessage } from "./validators";

const logger = getLogger({ module: "useGaiaMessageEvents", tag: "solve" });

export const useGaiaMessageEvents = () => {
    const { data: isProactiveEnabled = features.isProactiveEnabled } = useFeatureFlag("isProactiveEnabled");

    useEffect(() => {
        // Reactive Message Handling
        const gaiaEventBusMessageSuccess = gaiaWsEventBus.on(
            "gaia_ws_orchestrator-invoke-success",
            ({ body }: GaiaWebSocketResponse) => {
                handleIncomingMessage(body as ReactiveMessagePayload);
            },
        );

        // Proactive Message Handling
        const gaiaEventBusProactiveMessageReceived = gaiaWsEventBus.on(
            "gaia_ws_message-suggestion",
            ({ body }: GaiaWebSocketResponse) => {
                //eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                const gaiaSuggestion = body as unknown as ProactiveMessagePayload;

                // All messages are coming through this event (reactive messages + proactive messages)
                if (!isProactiveEnabled || !isProactiveMessage(gaiaSuggestion)) return;

                // TODO: error messages should not return with messageType = proactive
                // working with gaia team to resolve this
                if (isProactiveErrorMessage(gaiaSuggestion)) {
                    logger
                        .child({
                            sessionId: gaiaSuggestion.sessionGroupId,
                        })
                        .error(
                            {
                                originalError: gaiaSuggestion.suggestion.message,
                            },
                            "GAIA websocket proactive error message response",
                        );
                    return;
                }

                handleIncomingMessage(gaiaSuggestion);
                triggerTabNotification("Expert Workspace", isProactiveEnabled);
                triggerProactiveSound(isProactiveEnabled);
            },
        );

        const gaiaEventBusOrchestratorFailure = gaiaWsEventBus.on("gaia_ws_orchestrator-invoke-error", (response) => {
            addErrorMessage("botFailure");
            const { message, body } = response as unknown as GaiaErrorMessage;
            const { sessionGroupId } = (JSON.parse(body) ?? {}) as unknown as GaiaErrorBody;
            logger
                .child({
                    sessionId: sessionGroupId,
                })
                .error(
                    {
                        originalError: message,
                    },
                    "GAIA websocket error message response",
                );
        });

        return () => {
            gaiaEventBusMessageSuccess();
            gaiaEventBusOrchestratorFailure();
            gaiaEventBusProactiveMessageReceived();
        };
    }, [isProactiveEnabled]);
};
