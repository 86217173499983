import { useExpertNoteStore } from "@soluto-private/expert-workspace-notepad";
import { removePii } from "@soluto-private/expert-workspace-pii";
import { truncateAnalyticsText } from "@soluto-private/eventualize-react";
import { getRootDispatcher } from "../analytics";
import { getCallDetails, useCallDetailsStore } from "../call-details";
import { useVerifiedCustomerStore } from "../customer-info/store";
import { useVerificationFlowStore } from "../customer-panel/components/customer-verification/store";
import { isVoiceTask } from "../sdk/agent/voice";
import { useSessionSummaryStore } from "../session-summary/store/useSessionSummaryStore";
import { useDisclosureStepStore } from "../sales-tools/disclosures/store/DisclosureStepStore";
import {
    useCurrentStepStore,
    useGaiaMessageStore,
    useHomeProductSessionStore,
    useOtherProductSessionStore,
} from "../sales-tools/home-product/stores";
import { useIntegratedFlowStore } from "../sales-tools/home-product/stores/useIntegratedFlowStore";
import { type Session } from "../sdk/sessions/types";
import { type Partner } from "../shared-types";
import { useOfferChecklistStore } from "../sales-tools/home-product/stores/useOfferChecklistStore";
import { getAppConfigSingleton } from "../config/appConfigSingleton";

const emitSessionEndEvent = async (previousSessionId: string, partner: Partner) => {
    const { summary, resolutionStatus, autogeneratedSummary } = useSessionSummaryStore.getState();
    const program = getAppConfigSingleton().partnersConfig[partner].programs[0].name;

    // TODO:// update program name to fetch from selection
    void getRootDispatcher()
        .withMeta({ Client: partner, Channel: "voice", SkipEDP: false, SkipMixpanel: false })
        .withIdentity("SessionId", previousSessionId)
        .withIdentity("SupportSessionId", previousSessionId)
        .dispatchBusinessEvent("SupportSessionClosed", {
            EndCallReason: resolutionStatus ?? "not resolved",
            EndCallSubReason: "",
            SupportSessionNotes: summary,
            AutoGeneratedSummary: autogeneratedSummary,
            ProgramName: program,
        });

    const { note } = useExpertNoteStore.getState();
    if (note) {
        const piiScrubbedNote = await removePii(note);
        void getRootDispatcher()
            .withMeta({ Client: partner, Channel: "voice", SkipEDP: false, SkipMixpanel: true, SkipOpenSearch: true })
            .withIdentity("SessionId", previousSessionId)
            .withIdentity("SupportSessionId", previousSessionId)
            .dispatchBusinessEvent("SaveExpertNote", {
                note: truncateAnalyticsText(piiScrubbedNote),
            });
    }
};

export const onSessionEnd = (previousSession: Session, previousSessionPartner: Partner, newSession: Session) => {
    /*
     * We need to reset a few stores when a new session comes in
     * Reset customer verification status
     * Reset sale session
     * Reset CSD Call Details
     */
    if (newSession.kind === "without-customer") {
        // Legacy SupportSessionClosed event
        void emitSessionEndEvent(previousSession.id, previousSessionPartner);
        useVerificationFlowStore.getState().resetSteps();
        useCallDetailsStore.getState().resetCallDetails();
        useHomeProductSessionStore.getState().resetSaleSession();
        useOtherProductSessionStore.getState().resetSaleSession();
        useVerifiedCustomerStore.getState().reset();
        useGaiaMessageStore.getState().resetEvents();
        useCurrentStepStore.getState().resetCurrentStepStore();
        useIntegratedFlowStore.getState().resetIntegratedStepStore();
        useDisclosureStepStore.getState().resetCurrentStepStore();
        useOfferChecklistStore.getState().resetData();
    }
};

export const onSessionStart = async (newSession: Session, newSessionPartner: Partner) => {
    if (!isVoiceTask(newSession.currentTask)) return;

    const task = newSession.currentTask;

    useSessionSummaryStore.getState().resetSessionSummary();
    const data = await getCallDetails(task.asurionCallId);
    useCallDetailsStore.getState().setCallDetails(data ?? {});

    const voiceTaskConvProp = task.conversations;
    const tfnDnis = voiceTaskConvProp ? voiceTaskConvProp.conversation_attribute_8 : null;

    await getRootDispatcher()
        .withExtra({
            UUI_ANI: data?.ANI,
            CallDirection: task.callDirection,
            CVPCallId: task.asurionCallId,
            TfnDnis: tfnDnis,
            ContactSource: data?.ClientCode,
            ICMRouterKey: data?.RouterCallKey,
            ICMRouterDay: data?.RouterCallDay,
        })
        .withMeta({ Client: newSessionPartner, Channel: "Call" })
        .dispatchBusinessEvent("SupportSessionStarted");
};
