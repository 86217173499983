import { Button, Card, Flex, Skeleton, Stack, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useSalesLeaderboard } from "../hooks/useSalesLeaderboard";
import { userCache } from "../../../shared-utils";
import { expertWorkspaceWebSocketEventBus } from "../../../websocket";
import { getLastSaturdayDate } from "../utils/getLastSaturdayDate";
import { CaretDown, CaretUp } from "../../../common-ui";
import { getTop3AndSelf } from "../utils/getTop3AndSelf";
import classes from "./SalesLeaderboard.module.css";

export function SalesLeaderboard() {
    const { data: leaderboardData, isLoading: isLoadingLeaderboard } = useSalesLeaderboard();
    const [isExpanded, setIsExpanded] = useState(false);
    const queryClient = useQueryClient();
    useEffect(() => {
        const subscription = expertWorkspaceWebSocketEventBus.on("ws_sales-event", (payload) => {
            void queryClient.setQueryData(["sales-leaderboard"], payload);
        });

        return () => {
            subscription();
        };
    }, [queryClient]);

    return (
        <Flex mt="1.625rem" className={classes.defaultCard} p="1rem" bg="dark.8" direction="column">
            <Flex justify="space-between">
                <Text fw="700" size="lg" c="dark.0" mb="1.5rem" mt="0.5rem">
                    Your team leaderboard
                </Text>
            </Flex>
            <Flex mt="0.5rem" w="100%" justify="space-between" mb="0.75rem">
                <Text fw="700" size="xs" c="dark.2">
                    Expert
                </Text>
                <Text fw="700" size="xs" c="dark.2">
                    Total enrolls
                </Text>
            </Flex>
            <Stack bg="dark.8" align="stretch" justify="center" gap="sm">
                {/* eslint-disable-next-line no-nested-ternary */}
                {isLoadingLeaderboard ? (
                    <Flex direction="column" align="center" justify="center" h="200px">
                        <Skeleton mb="0.5rem" h="15%" />
                        <Skeleton mb="0.5rem" h="15%" />
                        <Skeleton mb="0.5rem" h="15%" />
                        <Skeleton mb="0.5rem" h="15%" />
                    </Flex>
                ) : leaderboardData && leaderboardData.length > 0 ? (
                    (isExpanded || !userCache.employeeId
                        ? leaderboardData
                        : getTop3AndSelf(userCache.employeeId, leaderboardData)
                    ).map((info) => (
                        <Card
                            className={
                                userCache.employeeId === info.employeeId
                                    ? `${classes.purpleGradient} ${classes.purpleBorder}`
                                    : classes.grayLeaderboardEntry
                            }
                            key={info.employeeId}
                        >
                            <Flex w="100%" justify="space-between">
                                <Text size="sm">
                                    {info.order + 1}. {info.employeeName}
                                </Text>
                                <Text size="sm">{info.numSales}</Text>
                            </Flex>
                        </Card>
                    ))
                ) : (
                    <Text p="3rem 1rem" size="xs" c="dark.2">
                        Waiting for more sales data—check back later!
                    </Text>
                )}
            </Stack>
            <Flex align="center" mt="0.75rem">
                <Card mr="0.25rem" p="0" w="12px" h="12px" className={classes.purpleGradient} radius="xl" />
                <Text size="xs" c="dark.2">
                    <strong>Updating live:</strong> {getLastSaturdayDate()} - Present
                </Text>
            </Flex>
            <Flex justify="center">
                <Button
                    onClick={() => setIsExpanded(!isExpanded)}
                    mt="1.25rem"
                    rightSection={
                        isExpanded ? (
                            <CaretUp fill="var(--mantine-color-dark-1)" />
                        ) : (
                            <CaretDown fill="var(--mantine-color-dark-1)" />
                        )
                    }
                    size="sm"
                    maw="13rem"
                    variant="outline"
                    c="dark.1"
                    color="dark.1"
                >
                    {isExpanded ? "Collapse" : "Show full leaderboard"}
                </Button>
            </Flex>
        </Flex>
    );
}
